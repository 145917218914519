<template>
  <div class="container mx-auto mt-24">
    <div class="md:mx-2">
      <h1 class="text-3xl md:text-3xl uppercase font-bold">Place an order</h1>
      <p class="text-base leading-normal">It's fast, secure, and confidential.</p>

      <section class="mb-4 mx-auto">
        <div id="parent" class="overflow-hidden bg-white border border-gray-400 rounded shadow-md">
          <div class="p-2 md:p-3 space-x-2 border-b border-gray-400 md:space-x-4">
            <button class="tabLinks px-1 md:px-5 py-2.5 font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 text-blue-500 rounded-lg text-xs md:text-sm" title="Academic writing" @click="selectForm('academic', $event)">Academic writing</button>
            <button class="tabLinks px-1 md:px-5 py-2.5 font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 text-blue-500 rounded-lg text-xs md:text-sm" title="Programming" @click="selectForm('programming', $event)">Programming</button>
            <button class="tabLinks px-1 md:px-5 py-2.5 font-medium bg-blue-50 hover:bg-blue-100 hover:text-blue-600 text-blue-500 rounded-lg text-xs md:text-sm" title="Calculations" @click="selectForm('calculations', $event)">Calculations</button>
          </div>
          <div class="p-3">
            <div id="academic" class="tabContent">
              <academic />
            </div>
            <div id="programming" class="tabContent" style="display: none" >
              <programming/>
            </div>
            <div id="calculations" class="tabContent" style="display: none" >
              <calculations/>
            </div>
          </div>
          <auth/>
        </div>
      </section>


    </div>
  </div>
</template>

<script>
import Academic from "@/components/form/Academic";
import Programming from "@/components/form/Programming";
import Calculations from "@/components/form/Calculations";
import Info from "@/components/order/Info";
import Auth from "@/components/user/Auth";

export default {
  name: "Order",
  components: {Auth, Info, Calculations, Programming, Academic},
  metaInfo: {
    title: "Place an order at CustomWritingHive.com"
  },
  data() {
    return {

    }
  },
  methods: {
    selectForm(selected, event) {
      let i, tabContent, tabLinks;
      tabContent = document.getElementsByClassName("tabContent");
      for (i = 0; i < tabContent.length; i++) {
        tabContent[i].style.display = "none";
      }
      tabLinks = document.getElementsByClassName("tabLinks");
      for (i = 0; i < tabLinks.length; i++) {
        tabLinks[i].className = tabLinks[i].className.replace(" bg-blue-50", "");
      }
      document.getElementById(selected).style.display = "block";
      event.currentTarget.className += " bg-blue-200";
    }
  }
}
</script>

<style scoped>

</style>
