<template>
  <div class="w-56 md:relative my-4 md:my-0">
    <div class="bg-blue-100 w-56 border border-blue-200 text-blue-600 text-lg rounded-lg shadow-lg md:fixed">
      <div class="px-2 py-4">
        <div class="space-y-1 font-bold">
          <p id="paperType" class="text-xs">{{ order.paperType.title }}</p>
          <p id="academicLevel" class="text-xs">{{ order.academicLevel.title }}</p>
          <p id="discipline" class="text-xs">{{ order.discipline.name }}</p>
        </div>
        <div class="border border-white my-2"></div>
        <div class="space-y-2">
          <div v-if="order.pages >= 1" class="flex justify-between text-xs font-medium text-blue-500">
            <span>{{ order.pages }} page<template v-if="order.pages > 1">s</template> x ${{ order.cpp }}</span>
            <span>${{ order.pages * order.cpp }}</span>
          </div>
          <div v-if="order.charts >= 1" class="flex justify-between text-xs font-medium text-blue-500">
          <span>{{ order.charts }} chart<template v-if="order.charts > 1">s</template> x
            ${{ order.spacing === 'double' ? (order.cpp * 0.50).toFixed(2) : (order.cpp * 0.25).toFixed(2) }}
          </span>
            <span>
            ${{
                order.spacing === 'double' ?
                    (order.cpp * order.charts * 0.50).toFixed(2) :
                    (order.cpp * order.charts * 0.25).toFixed(2)
              }}
          </span>
          </div>
          <div v-if="order.slides >= 1" class="flex justify-between text-xs font-medium text-blue-500">
          <span>{{ order.slides }} slide<template v-if="order.slides > 1">s</template> x
            ${{ order.spacing === 'double' ? (order.cpp * 0.50).toFixed(2) : (order.cpp * 0.25).toFixed(2) }}
          </span>
            <span>
            ${{
                order.spacing === 'double' ?
                    (order.cpp * order.slides * 0.50).toFixed(2) :
                    (order.cpp * order.slides * 0.25).toFixed(2)
              }}
          </span>
          </div>
          <div v-if="order.pages === 0 && order.slides === 0 && order.charts === 0" class="text-xs">
            ${{ order.cpp }} per page
          </div>
        </div>
        <div class="border border-white my-2"></div>
      </div>
      <div class="flex flex-col justify-center">
        <p v-if="order.price > 0" class="text-sm font-bold uppercase text-center block">
          Grand total: ${{ grandTotal }}
        </p>
        <button
            class="block flex items-center space-x-2 m-2 bg-yellow-600 text-white uppercase font-bold hover:bg-yellow-500 hover:text-yellow-100 text-base px-4 py-2 rounded transition duration-300"
            @click="handleSubmit"
        >
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 15v2m-6 4h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2zm10-10V7a4 4 0 0 0-8 0v4h8z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
          <span>Safe checkout</span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Info",
  props: ['order'],
  methods: {
    handleSubmit() {
      this.$store.dispatch('createOrder')
          .then(() => {
            this.$emit('order-checkout')
            this.$router.push('dashboard');
          })
          .catch(error => console.error('error creating order', error));
    }
  },
  computed: {
    grandTotal() {
      return Number(this.order.price)?.toFixed(2);
    }
  }
}
</script>

<style scoped>

</style>
