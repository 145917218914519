<template>
  <div class="py-10 container mx-auto">
    <div class="flex items-center justify-center space-x-4">
      <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="31.311" height="31.92">
        <path
            d="M2.209 26.569S1.131 29.889 0 31.049c0 0 .338.357 1.209.869 0 0 2.238-2.312 3.439-3.133 0 .002-.347-2.701-2.439-2.216zM30.305 4.062C26.391-3.411 19.98 1.715 19.98 1.715c8.543 2.505 7.371 13.036 7.371 13.036 6.6-3.913 2.954-10.689 2.954-10.689z"/>
        <path
            d="M14.096 19.202c2.027-2.604 12.12-8.984 12.133-8.992-.438-1.187-.945-2.162-1.489-2.966-2.796 1.178-13.249 9.877-13.249 9.877 3.717-4.964 12.103-11.259 12.117-11.27-2.716-2.761-5.761-2.262-5.761-2.262C12.734 8.257 6.18 16.194 3.714 19.244c-.311.356-.644.771-1.001 1.247 0 0 2.854-2.358 3.785-1.207.934 1.152.658 3.457.658 3.457s1.865-2.358 2.963-1.152c1.097 1.206.383 2.854 0 3.291 0 0 1.59-1.591 1.92-.986.328.603.054 1.975-.934 3.235 0 0 .182-.21.436-.537 7.552-9.265 15.812-11.841 15.812-11.841a26.13 26.13 0 00-.621-2.972c-3.767 1.547-12.636 7.423-12.636 7.423z"/>
        <path
            d="M11.408 24.88c-.056-.438-1.289 0-1.289 0 .191-.438-.083-1.617-.604-2.275-.521-.658-2.359.138-2.359.138 0-.55-.74-1.947-1.563-2.524-.821-.574-2.88.274-2.88.274.357 3.1-.504 6.077-.504 6.077 4.367-1.648 2.439 2.219 2.439 2.219 2.881-1.698 6.457-1.658 6.457-1.658.824-1.402.303-2.251.303-2.251z"/>
      </svg>
      <div class="">
        <h3 class="font-bold text-3xl">
          Ask for <span class="text-yellow-600 text-4xl italic">instant help</span> to raise your grades!
        </h3>
      </div>
    </div>
    <div class="flex items-center justify-center my-4">
      <router-link to="/order" class="my-blue-btn content-center">Order now</router-link>
      <span class="hidden"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "InstantHelp"
};
</script>

<style>

.my-btn {
  @apply py-3 px-8 text-lg rounded-lg font-bold tracking-wide;
}

.my-blue-btn {
  @apply my-btn bg-blue-600 hover:bg-blue-500 hover:text-blue-100 text-blue-200 shadow-lg transition duration-500 ring-2 ring-blue-300;
}
</style>
