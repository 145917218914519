<template>
  <div class="rc-ui-blocker OrderDetailsFiles rc-ui-blocker--size-m rc-ui-blocker--transparency-soft mx-5">
    <div class="OrderDetailsFiles__split--from-customer">
      <div class="OrderDetailsFiles__split__header">
        <div class="OrderDetailsFiles__split__title bold">Order file uploads</div>
        <div class="OrderDetailsFiles__split__count">
          {{ files.length }}
          file
          <template v-if="files.length > 1">s</template>
        </div>
      </div>
      <div class="OrderDetailsFiles__split__body">
        <div v-for="file in files"
             class="rc-ui-blocker OrderDetailsFilesCustomersFile rc-ui-blocker--size-xs rc-ui-blocker--transparency-soft">
          <div
              :title=file.name
              class="OrderDetailsFilesCustomersFile__link cursor-pointer"
              @click.prevent="downloadFile(file.upload, file.name)"
          >
            <div class="FileIcon">
              <div class="FileIcon__badge FileIcon__badge--rtf">{{ file.icon }}</div>
            </div>
            <span class="OrderDetailsFilesCustomersFile__subject">{{ file.name }}
              <div class="OrderDetailsFilesCustomersFile__file-description">Instructions / Guidelines
                <span class="OrderDetailsFilesCustomersFile__date">{{ formatDate(file.uploaded) }}</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <FileUploader :order-id="this.$route.params.id"/>
    </div>
  </div>
</template>

<script>

import FileUploader from "@/components/order/FileUploader";
import axios from "axios";
import {formatDate} from "@/components/order/common-functions";
import {BASE_URL} from "@/components/order/orderObjects.js";


export default {
  name: "Files",
  components: {FileUploader},
  data() {
    return {
      files: [],
    }
  },
  mounted() {
    this.getOrderFiles(this.$route.params.id)
  },
  methods: {
    getOrderFiles(orderId) {
      return new Promise(resolve => {
        axios({
          url: `${BASE_URL}/materials/${orderId}/`,
          method: 'GET',
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        })
            .then(response => {
              this.files = response.data
            })
            .catch(error => {
              console.log(error);
              console.dir(error);
              console.debug(error);
              resolve(error.response);
            })
      })
    },
    downloadFile(path, name) {
      axios.get(`${BASE_URL}${path}/`, {responseType: 'blob'})
          .then(response => {
            const fileUrl = window.URL.createObjectURL(new Blob([response.data]))
            const fileLink = document.createElement('a')

            fileLink.href = fileUrl
            fileLink.setAttribute('download', name)
            document.body.appendChild(fileLink)

            fileLink.click()
          })
          .catch(console.error)
    },
    formatDate,
  }
}
</script>

<style scoped>

</style>
