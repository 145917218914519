import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dashboard from "@/components/dashboard/Dashboard.vue";
import Order from "@/views/Order";
import ThankYou from "@/components/order/ThankYou";
import Cancelled from "@/components/order/Cancelled";
import ForgotPassword from "@/components/user/ForgotPassword";
import PasswordResetConfirm from "@/components/user/PasswordResetConfirm";
import Auth from "@/components/user/Auth";
import Payments from "@/components/dashboard/Payments";
import Support from "@/views/Support";
import PrivacyPolicy from "@/views/PrivacyPolicy";
import Guarantees from "@/views/Guarantees";
import OrderDetails from "@/components/order/OrderDetails";
import VueMeta from 'vue-meta'

Vue.use(VueMeta);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth
    },
    {
        path: '/order',
        name: 'Order',
        component: Order
    },
    {
        path: '/payments',
        name: 'Payments',
        component: Payments
    },
    {
        path: '/thank-you',
        name: 'ThankYou',
        component: ThankYou
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword
    },
    {
        path: '/cancelled',
        name: 'Cancelled',
        component: Cancelled
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/dashboard/order/:id/info',
        name: 'order_info',
        component: OrderDetails,
    },
    {
        path: '/reset_password/:uid/:token',
        name: 'PasswordResetConfirm',
        component: PasswordResetConfirm,
    },
    {
        path: '/support',
        name: 'Support',
        component: Support,
    },
    {
        path: '/guarantees',
        name: 'Guarantees',
        component: Guarantees,
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/writing-services',
        name: 'WritingServices',
        component: () => import(/* webpackChunkName: "writing-services" */ '../views/WritingServices.vue')
    },
    {
        // catch all 404 - define at the very end
        path: "*",
        component: () => import("../views/NotFound.vue")
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
