<template>
  <div class="bg-gradient-to-r from-blue-300 via-teal-400 to-yellow-300 -mt-16">
    <div class="container mx-auto">
      <div class="md:flex rounded-lg bg-teal-100 text-teal-700 transform -translate-y-32 md:-translate-y-16 mx-4">
        <div class="md:w-1/2 my-10 px-4">
          <h2 class="cta_title">We offer</h2>
          <ul class="text-lg font-medium lg:font-semibold">
            <li class="blue_cta_prop">
              <span>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"></path>
                </svg>
              </span>
              <span>Custom papers written from scratch</span>
            </li>
            <li class="blue_cta_prop">
              <span>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"></path>
                </svg>
              </span>
              <span>Ample deadlines from 8 hours</span>
            </li>
            <li class="blue_cta_prop">
              <span>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path
                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path
                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"
                    stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"></path></svg>
              </span>
              <span>70+ disciplines covered</span>
            </li>
            <li class="blue_cta_prop">
              <span>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg"><path
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"></path></svg>
              </span>
              <span>Confidentiality</span>
            </li>
            <li class="blue_cta_prop">
              <span>
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg"><path
                    d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                    stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"></path></svg>
              </span>
              <span>24/7 support</span>
            </li>
          </ul>
        </div>
        <div class="md:w-1/2 my-10 lg:px-10">
          <h2 class="cta_title">We guarantee</h2>
          <ul class="text-lg font-medium lg:font-semibold">
            <li class="yellow_cta_prop">
                    <span class="px-3">
                      <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                           xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                            stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="2"/>
                      </svg>
                    </span>
              <span>100% custom written papers</span>
            </li>
            <li class="yellow_cta_prop">
              <span class="px-3">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </span>
              <span>Timely delivery</span>
            </li>
            <li class="yellow_cta_prop">
              <span class="px-3">
               <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                 <path
                     d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                     stroke-linecap="round" stroke-linejoin="round"
                     stroke-width="2"/>
              </svg>
              </span>
              <span>Professionally qualified writers</span>
            </li>
            <li class="yellow_cta_prop">
              <span class="px-3">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </span>
              <span>Personal data security</span>
            </li>
            <li class="yellow_cta_prop">
              <span class="px-3">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </span>
              <span>Instant replies to your inquiries</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LftRghtBtns from "@/components/layout/LftRghtBtns";

export default {
  name: "CTA",
  components: {LftRghtBtns}
};
</script>

<style scoped>
.cta_title {
  @apply text-center text-4xl font-extrabold py-4 capitalize;
}

.blue_cta_prop {
  @apply hover:text-blue-600 hover:bg-blue-200 flex items-center space-x-2 px-2 md:px-5 rounded-full py-4 my-2 transition duration-500 ease-in-out;
}

.yellow_cta_prop {
  @apply hover:text-yellow-600 rounded-full hover:bg-yellow-200 px-5 py-4 my-2 flex items-center transition duration-500 ease-in-out;
}
</style>
