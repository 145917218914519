<template>
  <div>
    <Header/>
    <div class="container bg-blue-100 mx-auto border border-blue-400 rounded-lg my-8 p-8 shadow-lg">
      <h2 class='text-2xl my-2 font-bold'>Your order status has been updated.</h2>
      <p class='text-xl my-2 italic'>Thank you for completing your payment.</p>
      <p class='text-base my-1'>Our team will now start working on the order.</p>
      <button class='bg-blue-600 text-blue-100 rounded-lg py-2 px-3 my-4' @click="$router.push({path: '/dashboard'})">
        Continue to my orders
      </button>
      <p class='text-sm'>Feel free to ask any questions from your orders page.</p>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";

export default {
  name: "ThankYou",
  components: {Header},
  mounted() {
    document.title = 'BestDissertations - Thank you for your order.'
  }
}
</script>

<style scoped>

</style>
