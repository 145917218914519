<template>
  <div
      class="bg-gradient-to-l from-blue-600 via-yellow-200 to-teal-400 container mx-auto w-full rounded-lg py-4 transform -translate-y-32 md:-translate-y-14 shadow-lg">
    <div class="my-8">
      <div class="relative">
        <h2 class="text-center text-2xl md:text-4xl py-4 font-extrabold my-2 text-transparent bg-clip-text bg-gradient-to-r from-gray-600 via-blue-900 to-black">
          How our professional academic help works</h2>
        <div
            class="absolute top-0 left-0 w-1/3 h-2 rounded bg-gradient-to-r from-yellow-200 via-blue-200 to-blue-100 ml-8 transform -translate-y-4"></div>
        <div
            class="absolute bottom-0 right-0 w-1/3 h-2 rounded bg-gradient-to-r from-gray-100 via-yellow-300 to-teal-400 mr-8 transform translate-y-4"></div>
      </div>
      <p class="lg:px-16 mt-4 px-2 leading-loose my-2 text-left md:text-center font-normal text-lg md:font-medium text-black">
        Follow these four simple steps to have our academic writing experts complete your paper. We have a support
        team available 24/7 to assist you with any questions you may have.
      </p>
      <p class="lg:px-16 leading-relaxed my-2 text-center text-sm md:text-base text-black">
        Learn about our simple and straightforward ordering process.
      </p>
    </div>
    <div class="flex flex-col md:grid grid-cols-9 mx-auto px-2 md:px-4 text-blue-50">
      <!-- left -->
      <div class="flex flex-row-reverse md:contents">
        <div class="bg-blue-100 text-blue-600 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md">
          <h3 class="mb-3 font-bold text-xl flex items-center space-x-2">
            <span class="bg-white rounded-full h-12 w-12 flex items-center p-2">
              <svg class="fill-current" height="28.641" width="32" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M31.934 14.267h.002s-.004-.013-.008-.019a.673.673 0 00-.089-.21C31.085 12.275 26.018.517 24.86.054 23.605-.447 5.823 2.684 4.822 3.561 3.915 4.354.693 17.15.096 19.548c-.011.015-.027.029-.033.042a.937.937 0 00-.06.331.2.2 0 00-.003.014l.003-.002c-.064 1.812 2.253 8.084 3.065 8.549.876.501 25.046-.251 25.922-1.253.812-.928 3.464-11.004 2.944-12.962zm-9.479-.779c-.296 1.979-.801 4.75-1.354 5.226-.875.752-11.146 2.755-12.021 2.188-.542-.351-1.755-4.069-1.755-4.069l-4.512.853S4.861 5.854 5.526 5.272c.937-.82 17.583-3.752 18.756-3.283.792.317 4.872 10.443 4.872 10.443s-3.679.679-6.699 1.056z"/>
                <path
                    d="M4.383 15.395l19.79-2.946v-2.066l-19.79 3.561zM4.759 12.057l21.1-3.365V6.627l-21.1 3.979zM22.982 3.245L5.886 6.807v1.449l17.096-2.944z"/>
              </svg>
            </span>
            <span>Place your order</span>
          </h3>
          <p class="text-sm leading-relaxed tracking-wide font-medium">
            Fill out a simple, step-by-step order form with the requirements of your assignment.
          </p>
        </div>

        <div class="col-start-5 col-end-6 md:mx-auto relative mr-2 md:mr-10">
          <div class="h-full w-6 flex items-center justify-center">
            <div class="h-full w-1 bg-orange-600 pointer-events-none"></div>
          </div>
          <div class="absolute top-1/2 -mt-3 flex bg-orange-200 shadow-xl w-8 h-8 rounded-full items-center">
            <h1 class="mx-auto text-orange-600 font-bold text-lg">1</h1>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="flex md:contents">
        <div class="col-start-5 col-end-6 mr-2 md:mr-10 md:mx-auto relative">
          <div class="h-full w-6 flex items-center justify-center">
            <div class="h-full w-1 bg-orange-600 pointer-events-none"></div>
          </div>
          <div class="absolute top-1/2 -mt-3 flex items-center bg-orange-200 shadow-xl w-8 h-8 rounded-full">
            <h1 class="mx-auto text-orange-600 font-bold text-lg">2</h1>
          </div>
        </div>

        <div
            class="bg-yellow-200 text-yellow-600 col-start-6 col-end-10 rounded-xl shadow-xl my-4 mr-auto px-6 py-4">
          <h3 class="mb-3 font-semibold text-xl flex items-center space-x-2">
            <span class="bg-white rounded-full h-12 w-12 flex items-center p-3">
             <svg class="fill-current" height="32" width="17.503" xmlns="http://www.w3.org/2000/svg">
               <path
                   d="M12.656 7.88l-.022 6.542 4.869.493zM5.81 17.688s.969.402 1.325 0c0 0 .356-2.646 2.141-4.291V2.249S8.614-.147 6.167.007c-2.446.152-2.65 2.345-2.65 2.345s.023.32.053.88a7.284 7.284 0 005.196-.474S6.87 4.83 3.616 4.187c.087 2.07.164 5.62-.1 9.21 0 0 1.835 1.534 2.294 4.291z"/>
               <path
                   d="M11.634 7.88h-1.575v6.062c-2.265 1.823-2.341 4.637-2.341 4.637-.453.444-2.406-.097-2.406-.097-.582-3.056-2.565-4.541-2.565-4.541.177-2.114.232-4.213.229-6.062H0s.643 12.06 0 23.552c0 0 11.197.772 16.923.517l.426-15.974-6.092.095.377-8.189zm2.981 21.251l-12.125.258v-1.283l12.125-1.026v2.051zm0-3.079l-12.125.257V24.77l12.125-1.026v2.308zm0-5.389v2.053l-12.125.257V21.69l12.125-1.027z"/>
             </svg>
            </span>
            <span>Stay in touch</span>
          </h3>
          <p class="text-sm font-medium leading-snug tracking-wide text-yellow-600">
            Use a convenient online portal on the site to track the progress of your order, chat, and be ready to
            answer additional questions of your assigned writer.
          </p>
        </div>

      </div>
      <!-- left -->
      <div class="flex flex-row-reverse md:contents">
        <div class="bg-blue-100 text-blue-600 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md px-6 py-4">
          <h3 class="mb-3 font-bold text-xl flex items-center space-x-2">
            <span class="bg-white rounded-full h-12 w-12 flex items-center p-3">
             <svg class="fill-current" height="32" width="23.223" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.791 0l-.028 8.68 6.46.654z"/>
                <path
                    d="M15.436 0H0s.854 15.999 0 31.247c0 0 14.854 1.024 22.454.685l.563-21.192-8.083.125L15.436 0zM2.956 4.27h6.883l-.454 6.581H2.956V4.27zm15.581 24.137l-15.581.303v-2.42l15.582-1.286-.001 3.403zm0-5.753l-15.581.304v-2.42l15.582-1.286-.001 3.402zm0-9.458V16.6l-15.581.302v-2.421l15.581-1.285z"/>
              </svg>
            </span>
            <span>Check the draft</span>
          </h3>
          <p class="text-sm leading-relaxed tracking-wide font-medium">
            Thoroughly review the first draft. It allows you to evaluate the sentiment, style, and sources chosen by
            your writer. At this point in the process, you can give your approval or request changes to your order.
          </p>
        </div>

        <div class="col-start-5 col-end-6 md:mx-auto relative mr-2 md:mr-10">
          <div class="h-full w-6 flex items-center justify-center">
            <div class="h-full w-1 bg-orange-600 pointer-events-none"></div>
          </div>
          <div class="absolute top-1/2 -mt-3 flex items-center bg-orange-200 shadow-xl w-8 h-8 rounded-full">
            <h1 class="mx-auto text-orange-600 font-bold text-lg">3</h1>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="flex md:contents">
        <div class="col-start-5 col-end-6 mr-2 md:mr-10 md:mx-auto relative">
          <div class="h-full w-6 flex items-center justify-center">
            <div class="h-full w-1 bg-orange-600 pointer-events-none"></div>
          </div>
          <div class="absolute top-1/2 -mt-3 flex items-center bg-orange-200 shadow-xl w-8 h-8 rounded-full">
            <h1 class="mx-auto text-orange-600 font-bold text-lg">4</h1>
          </div>
        </div>
        <div
            class="order-1 bg-yellow-200 text-yellow-600 col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md px-6 py-4">
          <h3 class="mb-3 font-bold text-xl flex items-center space-x-2">
            <span class="bg-white rounded-full h-12 w-12 flex items-center p-2">
              <svg class="fill-current" height="32" width="27.758" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.432 10.001C27.286 8.78 14.506-.088 11.83.001S.85 9.547.479 11.3c-1.412 3.327.588 19.3 2.254 20.513 1.755 1.279 23.793-4.369 24.473-5.172.647-.765.732-14.877.226-16.64zm-1.617 15.302c-.416.812-21.16 6.187-22.111 4.987-.951-1.199-3.063-17.979-1.498-19.011.302-.198.413-.392 2.015-.567-.029-1.612.039-3.278.384-3.873.745-1.278 19.242-.046 19.833.773.148.207.221 1.16.254 2.242 1.232.036 1.02.112 1.124.235.613.715.415 14.401-.001 15.214z"/>
                <path
                    d="M24.021 8.117c-.56-.793-18.234-1.981-18.939-.743-.706 1.238-.16 7.452-.16 7.452l1.844 1.2-.316-.877s-.511-4.966.151-6.155c.66-1.188 16.233-.047 16.761.715.34.493.16 4.945.16 4.945l-4.648 7.7-1.574.524.084.056 1.852-.604 4.958-8.02c-.003.001.189-5.678-.173-6.193z"/>
                <path
                    d="M3.704 13.915s12.541 9.87 14.371 9.573c2.08-.338 7.313-10.961 7.313-10.961s-6.087 9.079-7.275 9.217c-1.446.168-14.409-7.829-14.409-7.829z"/>
                <path
                    d="M5.21 27.829l6.938-7.968 1.505.993zM21.206 19.564l3.904 4.598-4.797-3.29zM7.835 10.543c.134-.467 13.805-.025 13.876.22.096.319.093.967 0 1.189-.098.237-13.816.276-13.876 0-.059-.275-.13-.952 0-1.409zM7.835 13.636c.134-.466 13.805-.025 13.876.22.096.318.093.967 0 1.189-.098.236-13.816.275-13.876 0-.059-.276-.13-.953 0-1.409zM19.2 18.514c.366-.451.754-.951 1.146-1.473a178.619 178.619 0 00-9.882-.281c1.086.629 2.188 1.252 3.207 1.807 2.32.003 3.767-.015 5.529-.053z"/>
              </svg>
            </span>
            <span>Receive paper</span>
          </h3>
          <p class="text-sm font-medium leading-snug tracking-wide text-opacity-100">
            You will receive an editable format of your assignment after approving the draft.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks"
};
</script>

<style scoped>

</style>
