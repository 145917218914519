<template>
  <div class="container mx-auto border-b border-gray-500 my-4">
    <div class="py-32 md:flex justify-between space-x-2">
      <div class="relative">
        <dt>
          <div class="absolute flex items-center justify-center px-2">
            <svg class="h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" width="31.311" height="31.92">
              <path
                  d="M2.209 26.569S1.131 29.889 0 31.049c0 0 .338.357 1.209.869 0 0 2.238-2.312 3.439-3.133 0 .002-.347-2.701-2.439-2.216zM30.305 4.062C26.391-3.411 19.98 1.715 19.98 1.715c8.543 2.505 7.371 13.036 7.371 13.036 6.6-3.913 2.954-10.689 2.954-10.689z"/>
              <path
                  d="M14.096 19.202c2.027-2.604 12.12-8.984 12.133-8.992-.438-1.187-.945-2.162-1.489-2.966-2.796 1.178-13.249 9.877-13.249 9.877 3.717-4.964 12.103-11.259 12.117-11.27-2.716-2.761-5.761-2.262-5.761-2.262C12.734 8.257 6.18 16.194 3.714 19.244c-.311.356-.644.771-1.001 1.247 0 0 2.854-2.358 3.785-1.207.934 1.152.658 3.457.658 3.457s1.865-2.358 2.963-1.152c1.097 1.206.383 2.854 0 3.291 0 0 1.59-1.591 1.92-.986.328.603.054 1.975-.934 3.235 0 0 .182-.21.436-.537 7.552-9.265 15.812-11.841 15.812-11.841a26.13 26.13 0 00-.621-2.972c-3.767 1.547-12.636 7.423-12.636 7.423z"/>
              <path
                  d="M11.408 24.88c-.056-.438-1.289 0-1.289 0 .191-.438-.083-1.617-.604-2.275-.521-.658-2.359.138-2.359.138 0-.55-.74-1.947-1.563-2.524-.821-.574-2.88.274-2.88.274.357 3.1-.504 6.077-.504 6.077 4.367-1.648 2.439 2.219 2.439 2.219 2.881-1.698 6.457-1.658 6.457-1.658.824-1.402.303-2.251.303-2.251z"/>
            </svg>
          </div>
          <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Original custom papers</p>
        </dt>
        <dd class="mt-2 ml-16 text-base text-gray-500">
          All your papers are written from scratch. Additionally, each paper is checked for plagiarism to ensure we
          deliver only original and properly cited custom works.
        </dd>
      </div>
      <div class="relative my-4">
        <dt>
          <div class="absolute flex items-center justify-center px-2">
            <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="17.503" height="32">
              <path
                  d="M12.656 7.88l-.022 6.542 4.869.493zM5.81 17.688s.969.402 1.325 0c0 0 .356-2.646 2.141-4.291V2.249S8.614-.147 6.167.007c-2.446.152-2.65 2.345-2.65 2.345s.023.32.053.88a7.284 7.284 0 005.196-.474S6.87 4.83 3.616 4.187c.087 2.07.164 5.62-.1 9.21 0 0 1.835 1.534 2.294 4.291z"/>
              <path
                  d="M11.634 7.88h-1.575v6.062c-2.265 1.823-2.341 4.637-2.341 4.637-.453.444-2.406-.097-2.406-.097-.582-3.056-2.565-4.541-2.565-4.541.177-2.114.232-4.213.229-6.062H0s.643 12.06 0 23.552c0 0 11.197.772 16.923.517l.426-15.974-6.092.095.377-8.189zm2.981 21.251l-12.125.258v-1.283l12.125-1.026v2.051zm0-3.079l-12.125.257V24.77l12.125-1.026v2.308zm0-5.389v2.053l-12.125.257V21.69l12.125-1.027z"/>
            </svg>
          </div>
          <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Technical writing</p>
        </dt>
        <dd class="mt-2 ml-16 text-base text-gray-500">
          Your papers are written by professionally qualified writers to ensure delivery of quality services.
        </dd>
      </div>
      <div class="relative">
        <dt>
          <div class="absolute flex items-center justify-center px-2">
            <svg class="w-12 h-12" xmlns="http://www.w3.org/2000/svg" width="32" height="31.482">
              <path
                  d="M32 8.317L27.143 0s-.637.761-1.646 2.048C21.561 2.444 6.396 4.01 0 5.197c0 0 1.246 17.897 1.431 26.286 0 0 14.316-.932 25.393-.894l-.235-17.874A61.724 61.724 0 0132 8.317zM5.187 27.014L4.113 8.774l18.503-2.913c-2.101 2.886-4.571 6.521-6.589 10.186 0 0-3.681-4.637-6.184-6.846L7.93 12.807s3.975 4.563 8.317 11.262c0 0 2.569-3.567 6.714-7.844l-.43 9.715-17.344 1.074z"/>
            </svg>
          </div>
          <p class="ml-16 text-lg leading-6 font-medium text-gray-900">Technical writing</p>
        </dt>
        <dd class="mt-2 ml-16 text-base text-gray-500">
          Your writer will edit your custom assignment till it matches your requests.
        </dd>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantages"
};
</script>

<style scoped>

</style>
