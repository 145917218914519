import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import {getUUID} from "@/components/order/common-functions";

Vue.use(Vuex)
const BASE_URL = 'https://customwritinghive.herokuapp.com';

export default new Vuex.Store({
    state: {
        order: {},
        orders: [],
        files: [],
        user: null,
        token: localStorage.getItem('token') || '',
        amountDue: 0,
        recentOrders: [],
        errors: [],
        alert: false,
        uuid: null,
    },
    getters: {
        getOrders: state => state.orders,
        getOrder: state => state.order,
        getRecentOrders: state => state.recentOrders,
        getAmountDue: state => state.amountDue,
        getUser: state => state.user,
        getUUID: state => state.uuid,
    },
    mutations: {
        saveOrder(state, order) {
            state.order = order;
            localStorage.setItem('order', JSON.stringify(state.order));
        },
        updateOrder(state) {
            if (state.order.paperDetails === '') {
                state.order.paperDetails = 'Default order details'
            }
        },
        saveOrders(state, orders) {
            localStorage.setItem('orders', JSON.stringify(orders));
            state.orders = orders;
        },
        saveFiles(state, files) {
            state.files = files;
        },
        saveUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user))
        },
        saveToken(state, token) {
            localStorage.setItem('token', token);
        },
        recentOrders(state, orders) {
            state.recentOrders = orders
        },
        updateAmountDue(state, amount) {
            state.amountDue = amount
        },
        addErrors(state, errors) {
            state.errors = errors
            state.alert = true
        },
        closeAlert(state) {
            state.alert = false
        },
        saveUUID(state, uuid) {
            state.uuid = uuid;
        }
    },
    actions: {
        computeAmountDue({commit, state}) {
            let total = 0
            state.recentOrders.forEach(order => {
                console.log('order some', order)
                total += parseFloat(order.price)
            })
            commit('updateAmountDue', total)
        },
        loadOrders({commit, state}) {
            axios({
                url: `${BASE_URL}/orders/`,
                method: 'GET',
                headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                }
            })
                .then(response => {
                    state.orders = response.data
                    const sortedOrders = []
                    state.orders.forEach(order => {
                        if (order.status === 'CREATED') {
                            sortedOrders.push(order)
                        }
                    })
                    commit('recentOrders', sortedOrders)
                })
                .catch(error => {
                    // TODO toast errors for improved user experience
                    // commit('addErrors', error.response.data)
                    console.log('error loading orders', error);
                    console.debug(error);
                    console.dir(error);
                })
        },
        createOrder({commit, state}) {
            const ids = {
                academicLevel: state.order.academicLevel.id,
                paperFormat: state.order.paperFormat.id,
                deadline: state.order.deadline.id,
                discipline: state.order.discipline.id,
                paperType: state.order.paperType.id,
            }
            const uuid = getUUID();
            const data = {...state.order, uuid, ...ids};

            // save uuid for file and whatnot retrieval purposes
            commit('saveUUID', uuid);

            return new Promise((resolve, reject) => {
                axios({
                    url: `${BASE_URL}/orders/`,
                    data: data,
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        //    TODO saveAlert
                        localStorage.removeItem('order');
                        resolve(response);
                    })
                    .catch(error => {
                        //    TODO implement saveAlert for errors;
                        // todo send toast alert
                        const errors = []
                        for (const [key, value] of Object.entries(error.response.data)) {
                            errors.push(`${key}: ${value}`)
                        }
                        commit('addErrors', errors)
                        console.log('errors', errors);
                        reject('order creation error');
                    });
            })
        },
        getUser({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${BASE_URL}/auth/users/me/`,
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        commit('saveUser', response.data)
                        resolve(response.data)
                    })
                    .catch(error => {
                        reject(error)
                        console.log('error getting user', error)
                    });
            })
        },
        createUser({commit, state}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${BASE_URL}/users/`,
                    data: state.user,
                    method: 'POST',
                })
                    .then(response => {
                        commit('saveToken', response.data.token);
                        localStorage.removeItem('user');
                        //    TODO saveAlert
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error)
                        //    TODO implement saveAlert for errors;
                        console.log('error creating user', error);
                    });
            })
        },
        loginUser({commit, dispatch, state}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${BASE_URL}/auth/token/login/`,
                    data: state.user,
                    method: 'POST',
                })
                    .then(response => {
                        commit('saveToken', response.data.auth_token);
                        localStorage.removeItem('user');
                        //    TODO saveAlert
                        resolve(response);
                    })
                    .catch(error => {
                        //    TODO implement saveAlert for errors;
                        console.log('error creating order login..', error);
                        reject(error);
                    });
            })
        },
        fetchOrders(commit, state) {
            return new Promise(resolve => {
                axios({
                    url: `${BASE_URL}/orders/`,
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        commit('saveOrders', response.data);
                        console.log('global state', state.orders);
                        resolve(response.data);
                    })
                    .catch(error => {
                        // resolve(error.response);
                    });
            })
        },
        fetchOrder({commit, state}, id) {
            return new Promise(resolve => {
                axios({
                    url: `${BASE_URL}/orders/${id}/`,
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${state.token}`
                    }
                })
                    .then(response => {
                        commit('saveOrder', response.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.log('error fethcing order', error);
                        console.dir(error);
                        console.debug(error);
                        resolve(error.response);
                    })
            })
        },
        fetchOrderFiles({commit, state}, id) {
            return new Promise(resolve => {
                axios({
                    url: `${BASE_URL}/materials/${id}/`,
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${state.token}`
                    }
                })
                    .then(response => {
                        commit('saveFiles', response.data);
                        resolve(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                        console.dir(error);
                        console.debug(error);
                        resolve(error.response);
                    })
            })
        },
        postPayment({commit, state}, data) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `${BASE_URL}/payment/${data.order_id}/`,
                    data: data,
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${localStorage.getItem('token')}`
                    }
                })
                    .then(response => {
                        this.$router.push({path: '/thank-you'}).then(r => console.log(r));
                        // redirect users to payment successful page
                        console.log('response', response.data);
                        resolve(response);
                        //    TODO saveAlert
                    })
                    .catch(error => {
                        //    TODO implement saveAlert for errors;
                        console.log('error creating payment', error);
                        reject(error);
                    });
            });
        },
    },
    modules: {}
})
