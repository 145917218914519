import format from "date-fns/format";
import add from 'date-fns/add'
import {parseJSON} from "date-fns";
import {DISCIPLINES} from "@/components/order/orderObjects";
// generate order uuid for files and whatnot
import {v4 as uuidv4} from 'uuid';

function formatDate(date) {
    return format(parseJSON(date), "MMMM d, yyyy HH:mm aaa");
}

function addDurationToDate(duration, date) {
    return formatDate(add(parseJSON(date), duration));
}

function taskSizeFactor(id) {
    let factor;
    switch (id) {
        case 1:
            factor = 1;
            break;
        case 2:
            factor = 1.4;
            break;
        case 3:
            factor = 1.8;
            break;
        case 4:
            factor = 2.2;
            break;
        default:
            factor = 2.6;
    }
    return factor;
}

function deadlineFactor(deadline) {
    let factor;
    switch (deadline) {
        case 1:
            factor = 2.9;
            break;
        case 2:
            factor = 2.5;
            break;
        case 3:
            factor = 2.1;
            break;
        case 4:
            factor = 1.7;
            break;
        case 5:
            factor = 1.3;
            break;
        case 6:
            factor = 1.2;
            break;
        case 7:
            factor = 1.1;
            break;
        case 8:
            factor = 1.0;
            break;
        case 9:
            factor = .9;
            break;
        default:
            factor = 1;
    }
    return factor;
}

function disciplineFactor(id) {
    let isComplex = DISCIPLINES.find(x => x.id === id).isComplexAssignment;
    return isComplex ? 1.6 : 1;
}

function academicLevelFactor(id) {
    let factor = 1;
    switch (id) {
        case 1:
            factor = 1.0;
            break;
        case 2:
            factor = 1.3;
            break;
        case 3:
            factor = 1.6;
            break;
        case 4:
            factor = 1.9;
            break;
        case 5:
            factor = 2.2;
            break;
        default:
            break;
    }
    return factor;
}

function spacingFactor(spacing) {
    return spacing === "double" ? 1 : 2;
}

function formatDeadline(deadline) {
    const duration = {
        hours: 0,
        days: 0,
    };
    switch (deadline) {
        case "4 hours":
            duration.hours = 4;
            break;
        case "8 hours":
            duration.hours = 8;
            break;
        case "24 hours":
            duration.hours = 24;
            break;
        case "2 days":
            duration.days = 2;
            break;
        case "3 days":
            duration.days = 3;
            break;
        case "5 days":
            duration.days = 5;
            break;
        case "7 days":
            duration.days = 7;
            break;
        case "14 days":
            duration.days = 14;
            break;
        case "1 month":
            duration.days = 30;
            break;
    }
    return duration;
}

function durationForDeadlineId(id) {
    const duration = {
        hours: 0,
        days: 0,
    };
    switch (id) {
        case 1:
            duration.hours = 4;
            break;
        case 2:
            duration.hours = 8;
            break;
        case 3:
            duration.hours = 24;
            break;
        case 4:
            duration.days = 2;
            break;
        case 5:
            duration.days = 3;
            break;
        case 6:
            duration.days = 5;
            break;
        case 7:
            duration.days = 7;
            break;
        case 8:
            duration.days = 14;
            break;
        case 9:
            duration.days = 30;
            break;
    }
    return duration;
}

const getUUID = () => {
    return uuidv4();
}

export {
    formatDate,
    deadlineFactor,
    disciplineFactor,
    academicLevelFactor,
    taskSizeFactor,
    formatDeadline,
    addDurationToDate,
    spacingFactor,
    durationForDeadlineId,
    getUUID,
};
