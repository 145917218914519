<template>
  <form action="" class="">
    <Login/>
    <div class="flex justify-between items-center my-2 space-x-2">
      <label for="name" class="">Name (optional)</label>
      <input type="text" id="name" class="p-2 bg-gray-200 border-gray-600">
    </div>
    <div class="flex justify-between items-center my-2 space-x-2">
      <label for="phone" class="">Phone (optional)</label>
      <vue-tel-input v-model="user.phone"></vue-tel-input>
    </div>
  </form>
</template>

<script>
import Login from "@/components/user/Login";
export default {
  name: "Signup",
  components: {Login},
  data() {
    return {
      user: {
        phone: '',
        email: '',
        name: '',
      }
    }
  }
}
</script>

<style scoped>

</style>
