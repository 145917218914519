<template>
  <div class="my-3">
    <VueFileAgent
        ref="vueFileAgent"
        v-model="fileRecords"
        :accept="'image/*,video/*,.pdf,.zip,.doc,.docx,.ppsx,.pps,.ppt,.pptx'"
        :deletable="true"
        :errorText="{
                type: 'Invalid file type. Place it in a zip archive to upload.',
                size: 'Files should not exceed 100MB in size',
            }"
        :helpText="'Choose files to upload'"
        :maxFiles="14"
        :maxSize="'100MB'"
        :meta="true"
        :multiple="true"
        :theme="'list'"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        @select="filesSelected($event)"
    ></VueFileAgent>
    <button :disabled="!fileRecordsForUpload.length" class="my-2 bg-blue-600 text-blue-100 p-3 rounded-lg hover:bg-blue-500"
            @click="uploadFiles()">
      Upload {{ fileRecordsForUpload.length }} files
    </button>
  </div>
</template>

<script>
import {BASE_URL} from "@/components/order/orderObjects.js";

export default {
  name: "FileUploader",
  props: {
    orderId: String,
  },
  data: function () {
    return {
      fileRecords: [],
      uploadUrl: '',
      uploadHeaders: {},
      fileRecordsForUpload: [], // maintain an upload queue
    };
  },
  mounted() {
    this.uploadUrl = `${BASE_URL}/materials/${this.orderId}/`
    this.uploadHeaders.Authorization = `Token ${localStorage.getItem('token')}`
  },
  methods: {
    uploadFiles: function () {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
      this.fileRecords = [];
    },
    deleteUploadedFile: function (fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord);
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        var k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Are you sure you want to delete?')) {
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
        }
      }
    },
    fileDeleted: function (fileRecord) {
      var i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
}
</script>

<style scoped>

</style>
