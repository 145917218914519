<template>
  <div>
    <Header/>
    <div class="w-full h-full">
      <div class="mx-auto p-10">
        <h2 class="text-center font-bold uppercase text-xl py-4">Order payment summary</h2>
        <table class="table-auto w-full bg-blue-100 rounded-lg">
          <thead>
          <tr class="py-3">
            <th class="border border-blue-500 px-4 py-3 text-blue-600 font-bold">Order ID/Title</th>
            <th class="border border-blue-500 px-4 py-3 text-blue-600 font-bold">Pages</th>
            <th class="border border-blue-500 px-4 py-3 text-blue-600 font-bold">Charts</th>
            <th class="border border-blue-500 px-4 py-3 text-blue-600 font-bold">Slides</th>
            <th class="border border-blue-500 px-4 py-3 text-blue-600 font-bold">Amount ($)</th>
          </tr>
          </thead>
          <tbody class="py-3 border border-gray-200 px-2">
          <tr v-for="order in recentOrders" :key="order.id">
            <td class="border border-blue-500 px-4 py-2 text-blue-600 font-medium"><span class="float-left">#{{
                order.id
              }} {{ order.title }}</span></td>
            <td class="border border-blue-500 px-4 py-2 text-blue-600 font-medium text-center">{{ order.pages }}</td>
            <td class="border border-blue-500 px-4 py-2 text-blue-600 font-medium text-center">{{ order.charts }}</td>
            <td class="border border-blue-500 px-4 py-2 text-blue-600 font-medium text-center">{{ order.slides }}</td>
            <td class="border border-blue-500 px-4 py-2 text-blue-600 font-medium text-center">${{ order.price }}</td>
          </tr>
          </tbody>
        </table>
        <div class="flex items-center space-x-4 my-4 content-center">
          <button class="px-3 py-3 bg-yellow-600 text-white rounded-lg focus:outline-none">Amount due {{
              amountDue
            }}
          </button>
          <div ref="paypal"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import store from '../../store/index'

export default {
  name: "Payments",
  components: {Header},
  data() {
    return {
      loaded: false,
      recentOrders: [],
      amountDue: 0,
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   try {
  //     store.dispatch('loadOrders')
  //     store.dispatch('computeAmountDue')
  //     next()
  //   } catch (e) {
  //     next(false)
  //   }
  // },
  async beforeMount() {
    await store.dispatch('loadOrders')
    await store.dispatch('computeAmountDue')
  },
  mounted() {
    this.recentOrders = this.$store.getters.getRecentOrders
    this.amountDue = this.$store.getters.getAmountDue

    document.title = 'Order payments summary | BestDissertations.org'
    //  paypal integration
    const script = document.createElement('script');
    script.src = "https://www.paypal.com/sdk/js?client-id=ATyYK_7XoCjnvTbY0kKz0irVYUi_LPpOS9O109o7885rq3DnngZKRpJq_UmurKT7Tf0DS90pM5lIYbXq";
    script.addEventListener("load", this.setLoaded);
    document.body.appendChild(script);
  },
  methods: {
    setLoaded() {
      this.loaded = true;
      this.handlePayPal(this.amountDue, this.postPayment);
    },
    handlePayPal(amount, id, postPayment) {
      window.paypal.Buttons({
        createOrder: function (data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: amount,
              }
            }]
          });
        },
        onApprove: function (data, actions) {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(function (details) {
            // This function shows a transaction success message to your buyer.
            const data = {
              payment_id: details.id,
              status: details.status,
              payer_email: details.payer.email_address,
              amount: details.purchase_units[0].amount.value,
            }
            postPayment(id, data);
          });
        }
      }).render(this.$refs.paypal);
    },
    postPayment(id, data) {
      console.log('log user payment', data)
      // axios({
      //   url: `${BASE_URL}/payment/${id}/`,
      //   data: data,
      //   method: 'POST',
      //   headers: {
      //     'Authorization': `Token ${localStorage.getItem('token')}`
      //   }
      // })
      //     .then(response => {
      //       this.$router.push({path: '/thank-you'});
      //       // redirect users to payment successful page
      //       console.log('response', response.data);
      //       //    TODO saveAlert
      //     })
      //     .catch(error => {
      //       //    TODO implement saveAlert for errors;
      //       console.log('error creating payment', error);
      //     });
    },
  }
}
</script>

<style scoped>

</style>
