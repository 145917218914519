<template>
  <div class="hero w-full bg-gradient-to-bl from-gray-100 via-blue-200 to-gray-300 py-32 my-14 shadow">
    <lft-rght-btns/>
    <div class="relative container mx-auto my-12 space-x-4">
      <section class="my-4 md:my-0 text-center">
        <h1 class="font-bold my-2 text-5xl bg-clip-text text-transparent bg-gradient-to-bl from-teal-700 to-blue-500">
          A custom writing service for all your needs <span
            class="text-5xl md:text-8xl bg-clip-text text-transparent bg-gradient-to-r from-purple-800 via-red-300 to-red-700">24/7</span>
        </h1>
        <p class="py-4 tracking-normal text-2xl text-blue-700 font-medium px-8 md:px-16">
          Our academic writing service helps you take control of your studies in a professional way.
        </p>
        <div class="my-4">
          <router-link
              class="px-4 py-2 rounded-full bg-teal-600 text-white hover:text-teal-200 hover:bg-teal-700 ring-4 ring-yellow-300 text-xl transform duration-500"
              to="/order">
            Order custom paper
          </router-link>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

import LftRghtBtns from "@/components/layout/LftRghtBtns";

export default {
  name: "Hero",
  components: {LftRghtBtns},
  data() {
    return {
      order: {
        paperType: {
          "id": 2,
          "title": "Essay (any type)"
        }
      },
      user: {
        email: ""
      },
    };
  }
};

</script>
<style scoped>
.my-btn {
  @apply bg-yellow-600 hover:bg-yellow-200 hover:text-yellow-600 text-yellow-200 transition duration-500 ease-linear transform hover:-translate-y-1 hover:scale-110 py-3 px-8 text-lg rounded-lg font-bold tracking-wide;
}

.my-yellow-btn {
  @apply my-btn bg-yellow-600 hover:bg-yellow-200 hover:text-yellow-600 text-yellow-200;
}
</style>
