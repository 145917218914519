<template>
  <div id="child" :class="{ auth_classes: !elInsideParent }" class="md:w-1/2 lg:2/5 bg-white">
    <div v-if="!isLoggedIn" class="tex-lg mx-2">
      <div class="flex">
        <button class="tab font-bold text-lg active_tab" @click="openTab($event,'new')">New customer</button>
        <button class="tab font-bold text-lg" @click="openTab($event,'return')">Returning customer</button>
      </div>
      <div class="px-2">
        <div v-if="errors.length > 0" class="my-2 p-3 bg-red-500 text-red-100 rounded-lg">
          <p v-for="(error, index) in errors" :key="index">
            {{ error }}
          </p>
        </div>

        <div id="new" class="tab-content my-4">
          <form method="post">
            <div class="">
              <label class="block text-black text-lg" for="email">Email</label>
              <input
                  id="email"
                  v-model="user.email"
                  class="px-3 py-2 my-2 w-full border border-blue-100 rounded-lg focus:outline-white"
                  placeholder="Enter email"
                  type="email"
              >
            </div>
            <div>
              <label class="block text-black text-lg" for="password">Password</label>
              <input
                  id="password"
                  v-model="user.password"
                  class="px-3 py-2 my-2 w-full border border-blue-100 rounded-lg focus:outline-white"
                  placeholder="Enter password"
                  type="password"
              >
            </div>
            <div class="">
              <label class="block text-black text-lg" for="phone">Phone (optional)</label>
              <vue-tel-input
                  id="phone"
                  v-model="user.profile.phone"
                  class="px-3 py-1 my-2 w-full border-blue-100 rounded-lg focus:outline-white"
                  v-bind="phoneProps"
                  v-on:country-changed="countryChanged"
              >
              </vue-tel-input>
            </div>
            <div>
              <input
                  id="offers"
                  v-model="user.profile.discount_offers"
                  class="p-3 m-2"
                  type="checkbox"
              >
              <label class="text-lg text-black" for="offers">I agree to receive coupons, exclusive offers, and the
                latest news by email, SMS, phone, and other electronic means.</label>
            </div>
            <div>
              <input
                  id="tc"
                  v-model="user.profile.terms_conditions"
                  class="p-3 m-2"
                  type="checkbox"
              >
              <label class="text-lg text-black" for="tc">
                I agree to the <a class="cursor-pointer link" href="#">terms & conditions</a> and
                <router-link class="cursor-pointer link" to="/privacy-policy">privacy policy.</router-link>
              </label>
            </div>
          </form>
          <div class="py-8 text-center">
            <button class="btn bg-blue-600 text-lg text-blue-100 float-right my-4" @click="handleSubmit">
              Create account & Sign in
            </button>
          </div>
        </div>

        <div id="return" class="tab-content my-2 flex" style="display: none;">
          <form method="post">
            <div>
              <label class="w-1/2" for="signup_e">Email ID</label>
              <input
                  id="signup_e"
                  v-model="user.email"
                  class="px-3 py-2 my-2 w-full border border-blue-100 rounded-lg focus:outline-white"
                  placeholder="Enter email"
                  type="email"
              >
            </div>
            <div>
              <label class="w-1/2" for="password_s">Password</label>
              <input
                  id="password_s"
                  v-model="user.password"
                  class="px-3 py-2 my-2 w-full border border-blue-100 rounded-lg focus:outline-white"
                  placeholder="password"
                  type="password"
              >
            </div>
          </form>
          <div class="flex items-center justify-between text-lg py-4">
            <router-link class="link" to="/forgot-password">Forgot password?</router-link>
            <button class="btn bg-blue-600 text-blue-100 float-right my-2" @click="handleSignIn">Sign in</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoggedIn" class="m-4 flex space-x-8 items-center">
      <p class="text-2xl">Account</p>
      <p class="bg-green-200 text-green-600 rounded-lg p-3 text-sm">
        Your email is {{ user.email }}
      </p>
    </div>
  </div>
</template>

<script>
import Login from "@/components/user/Login";
import Signup from "@/components/user/Signup";

export default {
  name: "Auth",
  components: {Signup, Login},
  data() {
    return {
      isLoggedIn: false,
      user: {
        email: '',
        password: '',
        profile: {
          phone: '',
          discount_offers: false,
          terms_conditions: false,
          country_code: '',
          iso2: '',
          country_name: '',
        }
      },
      phoneProps: {
        placeholder: "Enter a phone number",
        required: false,
        name: "telephone",
        maxLen: 25,
      },
      errors: [],
      elInsideParent: false,
    }
  },
  created() {
    this.$store.dispatch('getUser')
        .then(() => {
          const user = this.$store.getters.getUser
          if (user.email && user.id) {
            this.isLoggedIn = true
          }
        })
  },
  updated() {
    this.$store.commit('saveUser', this.user);
  },
  mounted() {
    this.elInsideParent = this.insideParent();
    if (!this.elInsideParent) {
      document.title = "Sign in or create account at BestDissertations.org";
    }
    const savedUser = JSON.parse(localStorage.getItem('user'));
    if (savedUser) {
      this.user = {...this.user, ...savedUser}
    }
  },
  methods: {
    insideParent() {
      return !!document.getElementById('parent');
    },
    openTab(event, customer) {
      const tabs = document.getElementsByClassName('tab-content');
      for (let i = 0; i < tabs.length; i++) {
        tabs[i].style.display = 'none';
      }
      document.getElementById(customer).style.display = 'block';
      const tabLinks = document.getElementsByClassName('tab');
      for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].className = tabLinks[i].className.replace(" active_tab", "");
      }
      document.getElementById(customer).style.display = 'block';
      event.currentTarget.className += " active_tab ";
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm() {
      this.errors = [];
      if (!this.user.email) {
        this.errors.push('User email is required.');
      }
      if (!this.user.password) {
        this.errors.push('User password is required.');
      }
      if (this.user.password.length <= 8) {
        this.errors.push('User password must be 8 characters or more.');
      } else if (!this.validEmail(this.user.email)) {
        this.errors.push('A valid email is required.');
      }

      if (!this.errors.length) {
        return true;
      }
    },
    handleSignIn() {
      if (this.checkForm()) {
        this.$store.dispatch('loginUser')
            .then(() => {
              this.$store.dispatch('createOrder')
              this.$router.push('dashboard');
            })
            .catch(() => this.$router.go(-1))
      }
    },
    handleSubmit() {
      if (this.checkForm()) {
        this.$store.dispatch('createUser')
            .then(() => {
              this.$store.dispatch('createOrder')
              this.$router.push('dashboard');
            })
            .catch(() => this.$router.go(-1))
      }
    },
    countryChanged(country) {
      this.user.profile.country_code = country.dialCode;
      this.user.profile.country_name = country.name;
      this.user.profile.iso2 = country.iso2;
    },
  },

}
</script>

<style>

.btn {
  @apply px-2 py-2 cursor-pointer hover:bg-blue-500 hover:text-white focus:outline-none transition-all duration-200 rounded;
}

.active_tab {
  @apply text-blue-500 border-b-2 font-medium border-blue-500;
}

.tab {
  @apply text-gray-600 py-4 px-6 hover:text-blue-500 focus:outline-none;
}

.input_group {
  @apply flex flex-col items-center my-2 space-x-2;
}

.input_element {
  @apply p-2 bg-gray-200 border-gray-600;
}

.link {
  @apply hover:underline text-blue-500;
}

.auth_classes {
  @apply container mx-auto mt-24 my-12 bg-gray-50 border border-2 rounded-lg md:px-4 py-6 lg:px-8;
}

</style>
