<template>
  <div class="md:flex">
    <div class="flex-1 flex overflow-hidden">
      <form id="academic" enctype="multipart/form-data" method="post">
        <div class="order_item_group mt-3">
          <label class="order_item_label" for="paperType">Type of paper</label>
          <select
              id="paperType"
              v-model="order.paperType"
              class="order_item"
          >
            <option v-for="type in PAPER_TYPES" :key="type.id" :value="type">
              {{ type.title }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="discipline">Discipline</label>
          <select
              id="discipline"
              v-model="order.discipline"
              class="order_item"
              name="discipline"
          >
            <option v-for="type in DISCIPLINES" :key="type.id" :value="type">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="academicLevel">Academic level</label>
          <select
              id="academicLevel"
              v-model="order.academicLevel"
              class="order_item"
              name="academicLevel"
          >
            <option v-for="type in ACADEMIC_LEVELS" :key="type.id" :value="type">
              {{ type.title }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="id_title">Title</label>
          <input
              id="id_title"
              v-model="order.title"
              class="order_item"
              placeholder="Enter the title of your paper"
              type="text"
          />
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="paperDetails">Paper instructions</label>
          <textarea
              id="paperDetails"
              v-model="order.paperDetails"
              class="order_item h-24"
              placeholder="Write anything you feel is important for the writer to consider. An outline, a grading scale, and other documents may be uploaded as additional materials."
          ></textarea>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="files">Additional materials</label>
          <VueFileAgent
              id="files"
              ref="vueFileAgent"
              v-model="order.files"
              :accept="'image/*,video/*,.pdf,.zip,.doc,.docx,.ppsx,.pps,.ppt,.pptx'"
              :deletable="true"
              :errorText="{
                type: 'Invalid file type. Place it in a zip archive to upload.',
                size: 'Files should not exceed 100MB in size',
              }"
              :helpText="'Choose files to upload'"
              :maxFiles="14"
              :maxSize="'128MB'"
              :meta="true"
              :multiple="true"
              :theme="'list'"
              :uploadUrl="uploadUrl"
              @beforedelete="onBeforeDelete($event)"
              @delete="fileDeleted($event)"
              @select="filesSelected($event)"
          />
        </div>
        <div class="order_item_group mt-3">
          <label class="order_item_label" for="paperFormat">Paper format</label>
          <select
              id="paperFormat"
              v-model="order.paperFormat"
              class="order_item"
              name="paperFormat"
          >
            <option v-for="type in PAPER_FORMATS" :key="type.id" :value="type">
              {{ type.title }}
            </option>
          </select>
        </div>
        <div class="order_item_group mt-3">
          <label class="order_item_label" for="deadline">Deadline</label>
          <select
              id="deadline"
              v-model="order.deadline"
              class="order_item"
              name="paperType"
              @change="handleDeadline"
          >
            <option v-for="type in DEADLINES" :key="type.id" :value="type">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <div class="order_item_label"></div>
          <div class="mb-2 mt-0">
            We'll send you the order for review by {{ addDate }}
          </div>
        </div>
        <div class="order_item_group mb-2">
          <label class="order_item_label" for="spacing">Spacing</label>
          <div id="spacing" class="text-xl">
            <button
                :class="{'bg-blue-600 text-white':  order.spacing === 'single'}"
                class="btn left_btn bg-blue-100"
                @click="handleSpacing($event,'single')"
            >
              Single
            </button>
            <button
                :class="{'bg-blue-600 text-white':  order.spacing === 'double'}"
                class="btn right_btn bg-blue-100"
                @click="handleSpacing($event,'double')"
            >
              Double
            </button>
          </div>
        </div>
        <div class="order_item_group my-2">
          <div class="order_item_label"></div>
          <div v-if="order.spacing === 'single'">
            <span v-if="order.pages === 0">550</span>
            <span v-else>{{ 550 * order.pages }}</span> words
          </div>
          <div v-if="order.spacing === 'double'">
            <span v-if="order.pages === 0">275</span>
            <span v-else>{{ 275 * order.pages }}</span> words
          </div>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="id_pages">Pages</label>
          <div
              class="flex flex-row h-10 w-28 rounded-lg relative bg-transparent bg-blue-100 hover:bg-blue-200 text-black">
            <button class="h-full w-20 rounded-l cursor-pointer focus:outline-none"
                    @click="handleDecrement($event, 'pages')">
              <span class="m-auto text-2xl font-thin">−</span>
            </button>
            <input id="id_pages" v-model="order.pages"
                   class="bg-blue-100 hover:bg-blue-200 text-black appearance-none resize-x outline-none focus:outline-none text-center w-full font-semibold text-md md:text-basecursor-default flex items-center outline-none"
                   min="0"
                   name="custom-input-number"
                   type="number" value="0"/>
            <button class="h-full w-20 rounded-r cursor-pointer focus:outline-none"
                    @click="handleIncrement($event, 'pages')">
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
        <div class="order_item_group my-2">
          <label class="order_item_label" for="id_pages">Sources</label>
          <div
              class="flex flex-row h-10 w-28 rounded-lg relative bg-transparent bg-blue-100 hover:bg-blue-200 text-black">
            <button class="h-full w-20 rounded-l cursor-pointer focus:outline-none"
                    @click="handleDecrement($event, 'sources')">
              <span class="m-auto text-2xl font-thin">−</span>
            </button>
            <input id="id_sources" v-model="order.sources"
                   class="bg-blue-100 hover:bg-blue-200 text-black appearance-none resize-x outline-none focus:outline-none text-center w-full font-semibold text-md md:text-basecursor-default flex items-center outline-none"
                   min="0"
                   name="custom-input-number"
                   type="number" value="0"/>
            <button class="h-full w-20 rounded-r cursor-pointer focus:outline-none"
                    @click="handleIncrement($event, 'sources')">
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
        <div class="order_item_group my-2">
          <label class="order_item_label" for="id_charts">Charts</label>
          <div
              class="flex flex-row h-10 w-28 rounded-lg relative bg-transparent mt-1 bg-blue-100 hover:bg-blue-200 text-black">
            <button class="h-full w-20 rounded-l cursor-pointer focus:outline-none" data-action="decrement"
                    @click="handleDecrement($event, 'charts')">
              <span class="m-auto text-2xl font-thin">−</span>
            </button>
            <input id="id_charts" v-model="order.charts"
                   class="bg-blue-100 hover:bg-blue-200 text-black appearance-none resize-x outline-none focus:outline-none text-center w-full font-semibold text-md md:text-basecursor-default flex items-center outline-none"
                   min="0"
                   name="custom-input-number"
                   type="number" value="0"/>
            <button class="h-full w-20 rounded-r cursor-pointer focus:outline-none" data-action="increment"
                    @click="handleIncrement($event, 'charts')">
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="id_slides">PowerPoint slides</label>
          <div
              class="flex flex-row h-10 w-28 rounded-lg relative bg-transparent mt-1 bg-blue-100 hover:bg-blue-200 text-black">
            <button class="h-full w-20 rounded-l cursor-pointer focus:outline-none" data-action="decrement"
                    @click="handleDecrement($event, 'slides')">
              <span class="m-auto text-2xl font-thin">−</span>
            </button>
            <input id="id_slides" v-model="order.slides"
                   class="bg-blue-100 hover:bg-blue-200 text-black appearance-none resize-x outline-none focus:outline-none text-center w-16 font-semibold text-md md:text-basecursor-default flex items-center outline-none"
                   min="0"
                   name="custom-input-number"
                   type="number" value="0"/>
            <button class="h-full w-20 rounded-r cursor-pointer focus:outline-none" data-action="increment"
                    @click="handleIncrement($event, 'slides')">
              <span class="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
        </div>
      </form>
    </div>
    <info
        :order="order"
        v-on:order-checkout="uploadFiles"
    />
  </div>
</template>

<script>
import ProgrammingForm from "@/components/order/ProgrammingForm";
import Alert from "@/components/order/Alert";
import Info from "@/components/order/Info";
import {
  academicLevelFactor,
  deadlineFactor,
  disciplineFactor,
  spacingFactor,
} from "@/components/order/common-functions";
import {
  ACADEMIC_LEVELS,
  BASE_URL,
  DEADLINES,
  DISCIPLINES,
  PAPER_FORMATS,
  PAPER_TYPES
} from "@/components/order/orderObjects";
import {add} from "date-fns";

export default {
  name: "Academic",
  components: {
    ProgrammingForm,
    Alert,
    Info,
  },
  data() {
    return {
      order: {
        cpp: 10.99,
        pages: 0,
        slides: 0,
        charts: 0,
        sources: 0,
        spacing: 'double',
        title: '',
        paperType: {
          "id": 2,
          "title": "Essay (any type)"
        },
        discipline: {
          "id": 4,
          "discipline_group": "Humanities",
          "name": "English 101",
          "isComplexAssignment": false,
          "disciplineGroup": 1
        },
        academicLevel: {
          "id": 1,
          "title": "High school"
        },
        paperFormat: {
          "id": 3,
          "title": "MLA"
        },
        deadline: {
          "id": 3,
          "name": "24 hours"
        },
        paperDetails: '',
        files: [],
        price: '',
        customer: 1,
      },
      DEADLINES,
      DISCIPLINES,
      PAPER_TYPES,
      ACADEMIC_LEVELS,
      PAPER_FORMATS,
      today: "",
      duration: {hours: 24},
      errors: [],
      fileRecordsForUpload: [], // maintain an upload queue
      uploadUrl: "",
    };
  },
  created() {
    this.handleDate();
  },
  updated() {
    this.updateOrderCpp();
    this.order.price = this.getTotal();
    this.$store.commit('saveOrder', this.order);
  },
  computed: {
    addDate: function () {
      return add(this.today, this.duration);
    },
  },
  mounted() {
    const savedOrder = JSON.parse(localStorage.getItem('order'));
    if (savedOrder) {
      this.order = {...this.order, ...savedOrder}
    }
    document.title = 'Order now - CustomWritingHive.com';
  },
  methods: {
    // todo get errors and alert user
    // getErrors() {
    //   this.errors = this.$store.commit()
    // },
    getTotal() {
      const pageCost = this.order.cpp * this.order.pages;
      const slidesCost = this.order.spacing === 'double' ?
          this.order.cpp * this.order.slides * 0.50 :
          this.order.cpp * this.order.slides * 0.25;
      const chartsCost = this.order.spacing === 'double' ?
          this.order.cpp * this.order.charts * 0.50 :
          this.order.cpp * this.order.charts * 0.25;
      return Number.parseFloat((pageCost + slidesCost + chartsCost).toFixed(2));
    },
    updateOrderCpp() {
      const CPP = 10.99; // base rate
      const deadline_factor = deadlineFactor(this.order.deadline.id);
      const discipline_factor = disciplineFactor(this.order.discipline.id);
      const academic_level_factor = academicLevelFactor(this.order.academicLevel.id);
      const spacing_factor = spacingFactor(this.order.spacing);
      const factor = (deadline_factor + discipline_factor + academic_level_factor) * spacing_factor;
      this.order.cpp = Number.parseFloat((CPP * factor).toFixed(2));
    },
    handleFiles(event) {
      console.log('files changed');
      console.log(event.target.files);
    },
    handleDate() {
      this.today = new Date();
    },
    handleDeadline() {
      this.duration = this.formatDeadline(this.order.deadline.name)
    },
    formatDeadline(deadline) {
      const duration = {
        hours: 0,
        days: 0,
      };
      switch (deadline) {
        case "4 hours":
          duration.hours = 4;
          break;
        case "8 hours":
          duration.hours = 8;
          break;
        case "24 hours":
          duration.hours = 24;
          break;
        case "2 days":
          duration.days = 2;
          break;
        case "3 days":
          duration.days = 3;
          break;
        case "5 days":
          duration.days = 5;
          break;
        case "7 days":
          duration.days = 7;
          break;
        case "14 days":
          duration.days = 14;
          break;
        case "1 month":
          duration.days = 30;
          break;
      }
      return duration;
    },
    handleIncrement(event, element) {
      event.preventDefault();
      switch (element) {
        case "pages":
          this.order.pages++;
          break;
        case "charts":
          this.order.charts++;
          break;
        case "slides":
          this.order.slides++;
          break;
        case "sources":
          this.order.sources++;
          break;
      }
    },
    handleDecrement(event, element) {
      event.preventDefault();
      switch (element) {
        case "pages":
          if (this.order.pages > 0) {
            this.order.pages--;
          }
          break;
        case "charts":
          if (this.order.charts > 0) {
            this.order.charts--;
          }
          break;
        case "slides":
          if (this.order.slides > 0) {
            this.order.slides--;
          }
          break;
        case "sources":
          if (this.order.sources > 0) {
            this.order.sources--;
          }
          break;
      }
    },
    handleSpacing(event, spacing) {
      event.preventDefault();
      this.order.spacing = spacing;
    },

    //   uploading files with vue-file-agent to b2
    uploadFiles() {
      const uploadHeaders = {
        'Authorization': `Token ${localStorage.getItem('token')}`
      }
      const uuid = this.$store.getters.getUUID;
      const uploadUrl = `${BASE_URL}/${uuid}/`;
      this.uploadUrl = uploadUrl;

      // some sanity check here
      console.log("sanity check")
      console.log("uuid", uuid)
      console.log("url", this.uploadUrl)

      // perform upload with default uploader
      this.$refs.vueFileAgent.upload(uploadUrl, uploadHeaders, this.fileRecordsForUpload);
      this.fileRecordsForUpload = [];
      this.order.files = [];
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {

        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1);
        const k = this.fileRecords.indexOf(fileRecord);
        if (k !== -1) this.fileRecords.splice(k, 1);
      } else {
        if (confirm('Are you sure you want to delete?')) {

          // will trigger 'delete' event
          this.$refs.vueFileAgent.deleteFileRecord(fileRecord);
        }
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.order_item {
  @apply w-full px-2 py-2 my-2 block border border-gray-300 leading-tight md:w-3/4 focus:border-blue-200 rounded-lg focus:outline-none;
}

.order_item_label {
  @apply md:w-1/4 py-2 pr-2;
}

.order_item_group {
  @apply md:flex md:items-center;
}

#id_files {
  @apply border-dashed border-2 bg-blue-100 hover:bg-blue-200 px-4 py-8 my-2 rounded-lg md:w-3/4;
}

.btn {
  @apply px-2 py-2 cursor-pointer hover:bg-blue-300 hover:text-blue-700 focus:outline-none transition-all duration-200;
}

.left_btn {
  @apply rounded-r-none;
}

.middle_btn {
  @apply rounded-r-none rounded-l-none;
}

.right_btn {
  @apply rounded-l-none;
}

.active {
  @apply bg-blue-600 text-white;
}

</style>
