<template>
  <div class="w-full h-full">
    <PropagateLoader
        :loading=loading
        class="w-12 h-12 mx-auto py-10"
        color='#1E88E5'
        size=20
    />
  </div>
</template>

<script>
import {PropagateLoader} from '@saeris/vue-spinners'

export default {
  name: "LoadingSpinner",
  props: ['loading'],
  components: {PropagateLoader,},
}
</script>

<style scoped>

</style>
