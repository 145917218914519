<template>
  <div>
    <div v-if="!resetLinkSent" class="container mx-auto p-32">
      <p>Enter your email to receive the password reset link.</p>
      <form action="" class="container mx-auto" @submit="resetPassword($event)">
        <input v-model="user.email" class="order_item" placeholder="email@example.com" type="email"/>
        <button
            class="bg-blue-600 text-white uppercase font-bold hover:bg-blue-400 text-base my-2 px-4 py-2 rounded-full mb-4 transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
            type="submit"
        >Submit
        </button>
      </form>
    </div>
    <div v-if="resetLinkSent"
         class="container mx-auto p-32 bg-white text-2xl my-10 flex flex-col justify-center text-center">
      <p class="mb-4 leading-relaxed">Kindly check you e-mail for a password reset link. Thank you.</p>
      <a class="bg-blue-600 text-blue-100 btn my-4 rounded-lg" @click="$router.push({path: '/'})">Go home</a>
    </div>
  </div>
</template>

<script>
import {BASE_URL} from "@/components/order/orderObjects.js";
import axios from "axios";

export default {
  name: "ForgotPassword",
  data() {
    return {
      user: {
        email: '',
      },
      resetLinkSent: false,
    }
  },
  mounted() {
    document.title = "Password reset request."
  },
  methods: {
    resetPassword(event) {
      event.preventDefault();
      axios({
        url: `${BASE_URL}/auth/users/reset_password/`,
        data: this.user,
        method: 'POST',
      })
          .then(() => {
            this.resetLinkSent = true;
          })
          .catch(error => {
            console.log('error', error);
          });

    }
  }
}
</script>

<style scoped>

</style>
