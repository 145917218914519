<template>
  <div>
    <Header/>
    <div class="container bg-red-100 mx-auto border border-red-400 rounded-lg my-8 p-8 shadow-lg">
      <h2 class='text-2xl my-2 font-bold leading-relaxed'>Your payment has not been processed.</h2>
      <p class='my-2 leading-loose'>There was a problem processing your payment.</p>
      <a class="block " href="mailto:info@customwritinghive.com">info@customwritinghive.com</a>
      <button class='bg-blue-600 text-blue-100 text-center rounded-lg py-2 px-3 my-4'
              @click="$router.push({path: '/dashboard'})">
        Continue to my orders
      </button>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";

export default {
  name: "Cancelled",
  components: {Header},
  mounted() {
    document.title = 'BestDissertations - Thank you for your order.'
  }
}
</script>

<style scoped>

</style>
