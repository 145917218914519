<template>
  <div v-if="showing" id="messageModal">
    <div class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div aria-hidden="true" class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div
            aria-labelledby="modal-headline"
            aria-modal="true" class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="flex text-center">
              <h2 class="text-2xl text-bold">Send message</h2>
            </div>
            <div class="flex items-center space-x-4 my-2">
              <div class="flex justify-center rounded-lg text-lg my-2 bg-blue-400" role="group">
                <div id="support" class="flex justify-center items-center rounded-l-lg space-x-2 bg-blue-600 focus:shadow-outline hover:cursor-pointer recipient-btn"
                     @click="handleRecipient('support')">
                  <svg class="svg-icon text-white fill-current">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                        d="M12 3c-4.97 0-9 4.03-9 9v7c0 1.1.9 2 2 2h4v-8H5v-1c0-3.87 3.13-7 7-7s7 3.13 7 7v1h-4v8h4c1.1 0 2-.9 2-2v-7c0-4.97-4.03-9-9-9z"/>
                  </svg>
                  <span>To Support</span>
                </div>
                <div id="writer" class="flex justify-center items-center rounded-r-lg space-x-2 focus:shadow-outline hover:cursor-pointer recipient-btn"
                     @click="handleRecipient('writer')">
                  <svg class="svg-icon" fill="#fff" height="18" viewBox="0 0 24 24" width="18">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path
                        d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/>
                  </svg>
                  <span>To Writer</span>
                </div>
              </div>
            </div>
            <form method="post">
            <textarea
                v-model="message.body"
                class="w-full mt-2 mb-4 p-2 border rounded-lg focus:outline-none"
                placeholder="Your message..."
            ></textarea>
            </form>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                type="button"
                @click="handleMessage">
              <span class="pr-2">Send Message</span>
              <svg class="svg-icon" fill="#fff" height="48" viewBox="0 0 24 24" width="48">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"/>
              </svg>
            </button>
            <button
                class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                type="button"
                @click.prevent="closeModal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {BASE_URL} from "@/components/order/orderObjects.js";

export default {
  name: "NewMessageModal",
  props: {
    showing: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      show: false,
      message: {
        recipient: 'support',
        fromWide: 'customer', // hard coded because this is customer interface
        body: '',
      }
    }
  },
  created() {
    this.message.order = parseInt(this.$route.params.id);
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    handleRecipient(recipient) {
      this.message.recipient = recipient;
      const element = document.getElementById(recipient);
      const btns = document.getElementsByClassName('recipient-btn');
      for (let i = 0; i < btns.length; i++) {
        btns[i].classList.remove("bg-blue-600");
      }
      element.className += " bg-blue-600";
    },
    handleMessage() {
      this.sendMessage(this.message);
    },
    sendMessage(data) {
      axios({
        url: `${BASE_URL}/messages/${data.order}/`,
        data: data,
        method: 'POST',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            //    TODO saveAlert
            this.message.body = ''
            this.$emit('close')
          })
          .catch(error => {
            //    TODO implement saveAlert for errors;
            console.log('error sending message', error);
          });
    }
  }
}
</script>

<style scoped>
.modal {
  display: none;
}

.recipient-btn {
  @apply hover:bg-blue-700 text-white px-4 py-2 mx-0 outline-none cursor-pointer;
}

.svg-icon {
  @apply h-5 w-5
}
</style>
