<template>
  <div>
    <Header/>
    <div class="flex space-x-4 my-2 items-center">
      <div class="tab active_tab" @click="openTab($event,'recent')">Recent</div>
      <div class="tab" @click="openTab($event,'finished')">Finished</div>
      <div class="tab" @click="openTab($event,'cancelled')">Canceled</div>
    </div>
    <div class="px-2 md:w-1/2">
      <div id="recent" class="tab-content my-4">
        <loading-spinner :loading="loading"/>
        <div v-if="!loading">
          <Empty v-if="recentOrders.length===0"/>
          <div v-for="order in recentOrders" v-else :key="order.id">
            <OrderCard :order="order"/>
          </div>
        </div>
      </div>

      <div id="finished" class="tab-content my-2 flex" style="display: none;">
        <Empty v-if="finishedOrders.length===0"/>
        <div v-for="order in finishedOrders" v-else :key="order.id">
          <OrderCard :order="order"/>
        </div>
      </div>
      <div id="cancelled" class="tab-content my-2 flex" style="display: none;">
        <Empty v-if="cancelledOrders.length===0"/>
        <div v-for="order in cancelledOrders" v-else :key="order.id">
          <OrderCard :order="order"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/Header.vue";
import Empty from "@/components/dashboard/Empty";
import OrderCard from "@/components/dashboard/OrderCard";
import axios from "axios";
import LoadingSpinner from "@/components/layout/LoadingSpinner";

const BASE_URL = 'https://customwritinghive.herokuapp.com';

export default {
  name: 'Dashboard',
  components: {LoadingSpinner, OrderCard, Empty, Header},
  data() {
    return {
      loading: true,
      isLoggedIn: false,
      title: 'My Orders',
      orders: [],
      recentOrders: [],
      finishedOrders: [],
      cancelledOrders: []
    }
  },
  mounted() {
    this.getUser()
    this.loadOrders();
    // this.orders = orders;
    document.title = this.title;
  },
  created() {
    this.$store.dispatch('getUser')
        .then(() => {
          const user = this.$store.getters.getUser
          if (user.email && user.id) {
            this.isLoggedIn = true
          } else {
            this.$router.push('/auth')
          }
        })
        .catch(() => this.$router.push('/auth'))
  },
  methods: {
    openTab(event, tab) {
      const tabs = document.getElementsByClassName('tab-content');
      for (let i = 0; i < tabs.length; i++) {
        tabs[i].style.display = 'none';
      }
      document.getElementById(tab).style.display = 'block';
      const tabLinks = document.getElementsByClassName('tab');
      for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].className = tabLinks[i].className.replace(" active_tab", "");
      }
      document.getElementById(tab).style.display = 'block';
      event.currentTarget.className += " active_tab ";
    },
    loadOrders() {
      axios({
        url: `${BASE_URL}/orders/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            this.orders = response.data;
            this.sortOrders()
            this.loading = false
          })
          .catch(error => {
            // TODO toast errors for improved user experience
            console.log('error', error);
            console.debug(error);
            console.dir(error);
          })
    },
    sortOrders() {
      this.orders.forEach(order => {
        if (order.status === 'CANCELLED') {
          this.cancelledOrders.push(order)
        } else if (order.status === 'FINISHED') {
          this.finishedOrders.push(order)
        } else {
          this.recentOrders.push(order)
        }
      })
    },
    getUser() {
      axios({
        url: `${BASE_URL}/auth/users/me`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => '')
          .catch(error => {
            this.$router.push('/auth')
          })
    }
  }
}
</script>
