const DEADLINES = [
    {
        "id": 1,
        "name": "4 hours"
    },
    {
        "id": 2,
        "name": "8 hours"
    },
    {
        "id": 3,
        "name": "24 hours"
    },
    {
        "id": 4,
        "name": "2 days"
    },
    {
        "id": 5,
        "name": "3 days"
    },
    {
        "id": 6,
        "name": "5 days"
    },
    {
        "id": 7,
        "name": "7 days"
    },
    {
        "id": 8,
        "name": "14 days"
    },
    {
        "id": 9,
        "name": "1 month"
    }
];
const PAPER_TYPES = [
    {
        "id": 1,
        "title": "Creative writing"
    },
    {
        "id": 2,
        "title": "Essay (any type)"
    },
    {
        "id": 3,
        "title": "Research paper"
    },
    {
        "id": 4,
        "title": "Speech"
    },
    {
        "id": 5,
        "title": "Book/movie review"
    },
    {
        "id": 6,
        "title": "Term paper"
    },
    {
        "id": 7,
        "title": "Coursework"
    },
    {
        "id": 8,
        "title": "Research proposal"
    },
    {
        "id": 9,
        "title": "Dissertation/Dissertation chapter"
    },
    {
        "id": 10,
        "title": "Thesis/Thesis chapter"
    },
    {
        "id": 11,
        "title": "Business plan"
    },
    {
        "id": 12,
        "title": "Case study"
    },
    {
        "id": 13,
        "title": "Admission essay"
    },
    {
        "id": 14,
        "title": "Article review"
    },
    {
        "id": 15,
        "title": "Annotated bibliography"
    },
    {
        "id": 16,
        "title": "Argumentative essays"
    },
    {
        "id": 17,
        "title": "Critical thinking"
    },
    {
        "id": 18,
        "title": "Capstone project"
    },
    {
        "id": 19,
        "title": "Question-Answer"
    },
    {
        "id": 20,
        "title": "Discussion Essay"
    },
    {
        "id": 21,
        "title": "Summary"
    },
    {
        "id": 22,
        "title": "Literature Analysis/Review"
    },
    {
        "id": 23,
        "title": "Memo/Letter"
    },
    {
        "id": 24,
        "title": "Lab Report"
    },
    {
        "id": 25,
        "title": "Outline"
    },
    {
        "id": 26,
        "title": "Analysis (any type)"
    },
    {
        "id": 27,
        "title": "Presentation/PPT"
    },
    {
        "id": 28,
        "title": "Report (any type)/Brief report"
    },
    {
        "id": 29,
        "title": "Reflection paper/Reflection essay"
    },
    {
        "id": 30,
        "title": "Response essay"
    },
    {
        "id": 31,
        "title": "Personal reflection"
    },
    {
        "id": 32,
        "title": "Other"
    },
    {
        "id": 33,
        "title": "Project"
    },
    {
        "id": 34,
        "title": "Poem"
    },
    {
        "id": 35,
        "title": "Blog post"
    },
    {
        "id": 36,
        "title": "Journal article"
    },
    {
        "id": 37,
        "title": "Homework"
    },
    {
        "id": 38,
        "title": "Assignment"
    },
    {
        "id": 39,
        "title": "College essay"
    },
    {
        "id": 40,
        "title": "Programming"
    },
    {
        "id": 41,
        "title": "Calculations"
    }
];
const ACADEMIC_LEVELS = [
    {
        "id": 1,
        "title": "High school"
    },
    {
        "id": 2,
        "title": "Undergrad. (yrs. 1-2)"
    },
    {
        "id": 3,
        "title": "Undergrad. (yrs. 3-4)"
    },
    {
        "id": 4,
        "title": "Graduate"
    },
    {
        "id": 5,
        "title": "PhD"
    }
];
const PAPER_FORMATS = [
    {
        "id": 1,
        "title": "APA 7"
    },
    {
        "id": 2,
        "title": "APA 6"
    },
    {
        "id": 3,
        "title": "MLA"
    },
    {
        "id": 4,
        "title": "Harvard"
    },
    {
        "id": 5,
        "title": "Other"
    },
    {
        "id": 6,
        "title": "Not applicable"
    }
];
const DISCIPLINES = [
    {
        "id": 1,
        "discipline_group": "Humanities",
        "name": "Art (Fine arts, Performing arts)",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 2,
        "discipline_group": "Humanities",
        "name": "Classic English Literature",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 3,
        "discipline_group": "Humanities",
        "name": "Composition",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 4,
        "discipline_group": "Humanities",
        "name": "English 101",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 5,
        "discipline_group": "Humanities",
        "name": "Film & Theater studies",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 6,
        "discipline_group": "Humanities",
        "name": "History",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 7,
        "discipline_group": "Humanities",
        "name": "Linguistics",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 8,
        "discipline_group": "Humanities",
        "name": "Literature",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 9,
        "discipline_group": "Humanities",
        "name": "Music",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 10,
        "discipline_group": "Humanities",
        "name": "Philosophy",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 11,
        "discipline_group": "Humanities",
        "name": "Poetry",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 12,
        "discipline_group": "Humanities",
        "name": "Religious studies",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 13,
        "discipline_group": "Humanities",
        "name": "Shakespeare",
        "isComplexAssignment": false,
        "disciplineGroup": 1
    },
    {
        "id": 14,
        "discipline_group": "Social Sciences",
        "name": "Anthropology",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 15,
        "discipline_group": "Social Sciences",
        "name": "Cultural and Ethnic Studies",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 16,
        "discipline_group": "Social Sciences",
        "name": "Economics",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 17,
        "discipline_group": "Social Sciences",
        "name": "Ethics",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 18,
        "discipline_group": "Social Sciences",
        "name": "Political science",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 19,
        "discipline_group": "Social Sciences",
        "name": "Psychology",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 20,
        "discipline_group": "Social Sciences",
        "name": "Social Work and Human Services",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 21,
        "discipline_group": "Social Sciences",
        "name": "Sociology",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 22,
        "discipline_group": "Social Sciences",
        "name": "Tourism",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 23,
        "discipline_group": "Social Sciences",
        "name": "Urban Studies",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 24,
        "discipline_group": "Social Sciences",
        "name": "Women's & gender studies",
        "isComplexAssignment": false,
        "disciplineGroup": 2
    },
    {
        "id": 26,
        "discipline_group": "Business and administrative studies",
        "name": "Business Studies",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 28,
        "discipline_group": "Business and administrative studies",
        "name": "Human Resources Management (HRM)",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 29,
        "discipline_group": "Business and administrative studies",
        "name": "International Relations",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 30,
        "discipline_group": "Business and administrative studies",
        "name": "Logistics",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 31,
        "discipline_group": "Business and administrative studies",
        "name": "Management",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 32,
        "discipline_group": "Business and administrative studies",
        "name": "Marketing",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 33,
        "discipline_group": "Business and administrative studies",
        "name": "Public Relations (PR)",
        "isComplexAssignment": false,
        "disciplineGroup": 3
    },
    {
        "id": 34,
        "discipline_group": "Natural Sciences",
        "name": "Astronomy (and other Space Sciences)",
        "isComplexAssignment": false,
        "disciplineGroup": 4
    },
    {
        "id": 37,
        "discipline_group": "Natural Sciences",
        "name": "Ecology",
        "isComplexAssignment": false,
        "disciplineGroup": 4
    },
    {
        "id": 38,
        "discipline_group": "Natural Sciences",
        "name": "Geography",
        "isComplexAssignment": false,
        "disciplineGroup": 4
    },
    {
        "id": 39,
        "discipline_group": "Natural Sciences",
        "name": "Geology (and other Earth Sciences)",
        "isComplexAssignment": false,
        "disciplineGroup": 4
    },
    {
        "id": 41,
        "discipline_group": "Natural Sciences",
        "name": "Zoology",
        "isComplexAssignment": false,
        "disciplineGroup": 4
    },
    {
        "id": 45,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Agriculture",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 46,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Application Letters",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 48,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Aviation",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 50,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Communications",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 51,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Criminal Justice",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 52,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Criminal law",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 53,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Education",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 55,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Environmental studies and Forestry",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 56,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Family and consumer science",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 57,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Health Care",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 58,
        "discipline_group": "Professions and Applied Sciences",
        "name": "International Trade",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 60,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Law",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 61,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Leadership Studies",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 62,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Medical Sciences (Anatomy, Physiology, Pharmacology etc.)",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 63,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Medicine",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 64,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Nursing",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 65,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Nutrition/Dietary",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 66,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Public Administration",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 67,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Sports",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 68,
        "discipline_group": "Professions and Applied Sciences",
        "name": "Technology",
        "isComplexAssignment": false,
        "disciplineGroup": 6
    },
    {
        "id": 69,
        "discipline_group": "Other",
        "name": "Other ",
        "isComplexAssignment": false,
        "disciplineGroup": 7
    },
    {
        "id": 70,
        "discipline_group": "Programming",
        "name": "Web programming",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 71,
        "discipline_group": "Programming",
        "name": "Desktop applications development",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 72,
        "discipline_group": "Programming",
        "name": "Mobile applications development",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 73,
        "discipline_group": "Programming",
        "name": "Database design and optimization",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 74,
        "discipline_group": "Programming",
        "name": "Data analysis and reports",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 75,
        "discipline_group": "Programming",
        "name": "Computer networking and cybersecurity",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 76,
        "discipline_group": "Programming",
        "name": "3D CAD modelling",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 77,
        "discipline_group": "Programming",
        "name": "Algorithms",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 78,
        "discipline_group": "Programming",
        "name": "Other",
        "isComplexAssignment": true,
        "disciplineGroup": 8
    },
    {
        "id": 25,
        "discipline_group": "Calculations",
        "name": "Accounting",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 27,
        "discipline_group": "Calculations",
        "name": "Finance",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 35,
        "discipline_group": "Calculations",
        "name": "Biology (and other Life Sciences)",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 36,
        "discipline_group": "Calculations",
        "name": "Chemistry",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 40,
        "discipline_group": "Calculations",
        "name": "Physics",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 42,
        "discipline_group": "Calculations",
        "name": "Computer science",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 43,
        "discipline_group": "Calculations",
        "name": "Mathematics",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 44,
        "discipline_group": "Calculations",
        "name": "Statistics",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 47,
        "discipline_group": "Calculations",
        "name": "Architecture, Building and Planning",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 49,
        "discipline_group": "Calculations",
        "name": "Civil Engineering",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 54,
        "discipline_group": "Calculations",
        "name": "Engineering",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    },
    {
        "id": 59,
        "discipline_group": "Calculations",
        "name": "IT, Web",
        "isComplexAssignment": true,
        "disciplineGroup": 9
    }
];
const PROGRAMMING_DISCIPLINES = DISCIPLINES.filter(discipline => discipline.discipline_group === "Programming");
const CALCULATIONS_DISCIPLINES = DISCIPLINES.filter(discipline => discipline.discipline_group === "Calculations");
const PROGRAMMING_EXTRA_SMALL_DELIVERABLES = [
    "Source code",
    "Screenshoots of outputs",
    "Simple charts and diagrams",
    "Short answers to questions",
];
const CALCULATIONS_EXTRA_SMALL_DELIVERABLES = [
    "simple computations",
    "Simple charts and diagrams",
    "Short answers to questions",
];
const PROGRAMMING_SMALL_DELIVERABLES = [
    ...PROGRAMMING_EXTRA_SMALL_DELIVERABLES
];
const CALCULATIONS_SMALL_DELIVERABLES = [
    ...CALCULATIONS_EXTRA_SMALL_DELIVERABLES
];
const PROGRAMMING_MEDIUM_DELIVERABLES = [
    "Source code",
    "Screenshoots of outputs",
    "Charts and diagrams",
    "Brief report",
];
const CALCULATIONS_MEDIUM_DELIVERABLES = [
    "Computations",
    "Charts and diagrams",
    "Detailed explanations"
];
const CALCULATIONS_LARGE_DELIVERABLES = [
    "Computations",
    "Charts and diagrams",
    "Detailed explanations",
    "Presentation"
];
const PROGRAMMING_LARGE_DELIVERABLES = [
    "Source code",
    "Screenshoots of outputs",
    "Charts and diagrams",
    "Documentation",
    "Detailed report",
    "Presentation"
];
const PROGRAMMING_DELIVERABLES_ICONS_DATA = [
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m10 20 4-16m4 4 4 4-4 4M6 16l-4-4 4-4'/%3E%3C/svg%3E",
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z' /%3E%3C/svg%3E",
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z' /%3E%3C/svg%3E",
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' /%3E%3C/svg%3E",
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z' /%3E%3C/svg%3E",
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z' /%3E%3C/svg%3E",
];
const PROGRAMMING_TASK_SIZES = [
    {
        id: 1,
        name: "Extra small",
        example: "up to 5 short practice problems or theoretical questions",
        deliverables: PROGRAMMING_EXTRA_SMALL_DELIVERABLES,
    },
    {
        id: 2,
        name: "Small",
        example: "6-10 short practice problems or theoretical questions",
        deliverables: PROGRAMMING_SMALL_DELIVERABLES,
    },
    {
        id: 3,
        name: "Medium",
        example: "11-20 practice problems, small lab report, simple algorithm for 1 scenario",
        deliverables: PROGRAMMING_MEDIUM_DELIVERABLES,
    },
    {
        id: 4,
        name: "Large",
        example: "extensive lab report or algorithm design project",
        deliverables: PROGRAMMING_LARGE_DELIVERABLES,
    }
];
const CALCULATION_TASK_SIZES = [
    {
        id: 1,
        name: "Extra small",
        example: "up to 5 short practice problems or theoretical questions",
        deliverables: CALCULATIONS_EXTRA_SMALL_DELIVERABLES,
    },
    {
        id: 2,
        name: "Small",
        example: "6-10 short practice problems or theoretical questions",
        deliverables: CALCULATIONS_SMALL_DELIVERABLES,
    },
    {
        id: 3,
        name: "Medium",
        example: "11-20 practice problems, small lab report, simple algorithm for 1 scenario",
        deliverables: CALCULATIONS_MEDIUM_DELIVERABLES,
    },
    {
        id: 4,
        name: "Large",
        example: "moderately complex problem analysis project (using applied math methods)",
        deliverables: CALCULATIONS_LARGE_DELIVERABLES,
    }
];
const CALCULATIONS_DELIVERABLES_ICONS_DATA = PROGRAMMING_DELIVERABLES_ICONS_DATA;
const BASE_URL = 'https://customwritinghive.herokuapp.com';

export {
    PROGRAMMING_DISCIPLINES, // programming
    PROGRAMMING_EXTRA_SMALL_DELIVERABLES,
    PROGRAMMING_SMALL_DELIVERABLES,
    PROGRAMMING_MEDIUM_DELIVERABLES,
    PROGRAMMING_LARGE_DELIVERABLES,
    PROGRAMMING_TASK_SIZES,
    PROGRAMMING_DELIVERABLES_ICONS_DATA,
    CALCULATIONS_DISCIPLINES, // calculations
    CALCULATIONS_EXTRA_SMALL_DELIVERABLES,
    CALCULATIONS_SMALL_DELIVERABLES,
    CALCULATIONS_MEDIUM_DELIVERABLES,
    CALCULATIONS_LARGE_DELIVERABLES,
    CALCULATION_TASK_SIZES,
    // todo calculations icon data
    CALCULATIONS_DELIVERABLES_ICONS_DATA,
    // shared details
    PAPER_TYPES,
    PAPER_FORMATS,
    DISCIPLINES,
    ACADEMIC_LEVELS,
    DEADLINES,
    // url
    BASE_URL,
};
