<template>
  <nav class="w-full text-xs md:text-base bg-blue-700 text-white flex justify-between p-4 mt-20">
    <div class="flex justify-between space-x-4">
      <router-link
          class="flex items-center text-center h-full md:space-x-2 font-semibold md:mx-3 p-2 hover:text-blue-200"
          to="/dashboard">
        <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4zMyAyNCI+PHBhdGggZD0iTTQuODggNC4xOWEuOC44IDAgMDEuOC0uOGgxMGEuOC44IDAgMDEwIDEuNkg1LjcxYS44LjggMCAwMS0uODMtLjh6bS4xOSA0LjQ4aDExLjJhLjgxLjgxIDAgMDAuOC0uOC44Mi44MiAwIDAwLS44LS44SDUuMDdhLjguOCAwIDAwLS44LjguODIuODIgMCAwMC44Ljh6bTE2LjI2IDYuNDh2OGEuOC44IDAgMDEtLjguOEguOGEuOC44IDAgMDEtLjgtLjhWMTVMMi4xOS42OUEuOC44IDAgMDEzIDBoMTUuMzJhLjguOCAwIDAxLjguNjlMMjEuMzMgMTV2LjExem0tMTkuNTctLjhoNC45MWEuOC44IDAgMDEuOC44IDMuMiAzLjIgMCAwMDYuNCAwIC44LjggMCAwMS44LS44aDQuOUwxNy42MyAxLjYzSDMuNjh6bTE4IDEuNjJoLTQuMzJhNC44NCA0Ljg0IDAgMDEtOS41NSAwSDEuNjN2Ni40M2gxOC4xVjE2em0tMi00LjQ4YS44LjggMCAwMC0uOC0uOEg0LjQ1YS44LjggMCAxMDAgMS42aDEyLjQzYS43Ny43NyAwIDAwLjgtLjh6IiBmaWxsPSIjZmZmIiBkYXRhLW5hbWU9IkxheWVyIDIiLz48L3N2Zz4="
            alt=""
            class="w-4 h-4 md:h-6 md:w-6"
        >
        <span>My orders</span>
      </router-link>
      <router-link
          class="flex items-center text-center h-full md:space-x-2 font-semibold md:mx-3 p-2 hover:text-blue-200"
          to="/payments">
        <svg class="w-4 h-4 md:h-6 md:w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        <span>Payments</span>
      </router-link>
    </div>
    <div class="flex justify-between space-x-4">
      <router-link
          class="hidden flex items-center text-center h-full md:space-x-2 font-semibold md:mx-3 pr-2 md:p-2 hover:text-blue-200"
          to="#">
        <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS4xNSAyNC41MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmZ9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzItMiIgZGF0YS1uYW1lPSJMYXllciAyIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yLjcyIDI0LjUyQTIuNzEgMi43MSAwIDAxMCAyMS44VjUuNDZhMi43MiAyLjcyIDAgMDEyLjcyLTIuNzJoMTAuMzl2MS42SDIuNzJBMS4xMiAxLjEyIDAgMDAxLjYgNS40NlYyMS44YTEuMTUgMS4xNSAwIDAwLjMzLjggMS4xIDEuMSAwIDAwLjc5LjMybDEyLjY3LS4wN2ExLjEzIDEuMTMgMCAwMDEuMTEtMS4xMnYtOC44aDEuNnY4LjhhMi43MyAyLjczIDAgMDEtMi43IDIuNzJsLTEyLjY3LjA3eiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTUuMDUgMTYuMzRoOC4wNnYxLjZINS4wNXpNNS4wNSAxMi45aDguMDZ2MS42SDUuMDV6TTUuMDUgOS4zNGg4LjA2djEuNkg1LjA1ek0xNi42OSA2Ljgydi0uNUEyLjM2IDIuMzYgMCAwMTE3IDUuMTdhMy44MiAzLjgyIDAgMDExLTEgNC4xOSA0LjE5IDAgMDAuOTQtLjgzIDEuMTkgMS4xOSAwIDAwLjIzLS43Mi43OC43OCAwIDAwLS4zMy0uNjggMS41NyAxLjU3IDAgMDAtLjkxLS4yMyA1LjI1IDUuMjUgMCAwMC0yLjM0LjY3TDE0Ljg1Ljg1QTYuNDcgNi40NyAwIDAxMTguMDggMGEzLjQ2IDMuNDYgMCAwMTIuMjQuNjggMi4yMSAyLjIxIDAgMDEuODMgMS44IDIuNDcgMi40NyAwIDAxLS4zNCAxLjNBNC43MSA0LjcxIDAgMDExOS41MSA1YTMuNjQgMy42NCAwIDAwLS44My43NCAxLjEyIDEuMTIgMCAwMC0uMTguNjZ2LjQxem0tLjIyIDIuMzRhMS4xOCAxLjE4IDAgMDEuMzEtLjg3IDEuMjggMS4yOCAwIDAxLjktLjI5IDEuMTkgMS4xOSAwIDAxLjg3LjMgMS4xNCAxLjE0IDAgMDEuMzIuODYgMS4xNSAxLjE1IDAgMDEtLjMyLjg1IDEuMTcgMS4xNyAwIDAxLS44Ny4zMSAxLjIzIDEuMjMgMCAwMS0uODktLjMgMS4xNCAxLjE0IDAgMDEtLjMyLS44NnoiLz48L2c+PC9nPjwvc3ZnPg=="
            alt=""
            class="w-4 h-4 md:h-6 md:w-6"
        >
        <span>Free inquiry</span>
      </router-link>
      <router-link
          class="flex items-center text-center h-full md:space-x-2 font-semibold md:mx-3 pr-2 md:p-2 hover:text-blue-200"
          to="/order">
        <img
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMS43OCAyNC4xIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZn08L3N0eWxlPjwvZGVmcz48ZyBpZD0iTGF5ZXJfMiIgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBpZD0iTGF5ZXJfMi0yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTIuNzIgMjQuMUEyLjcxIDIuNzEgMCAwMTAgMjEuMzhWNWEyLjcyIDIuNzIgMCAwMTIuNzItMi42OGg2Ljd2MS42aC02LjdBMS4xMiAxLjEyIDAgMDAxLjYgNXYxNi4zOGExLjE0IDEuMTQgMCAwMC4zMy44IDEuMSAxLjEgMCAwMC43OS4zMmwxMi42Ny0uMDdhMS4xMiAxLjEyIDAgMDAxLjExLTEuMTJ2LTguOGgxLjZ2OC44QTIuNzMgMi43MyAwIDAxMTUuNCAyNGwtMTIuNjcuMXoiLz48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01LjA1IDE1LjkyaDguMDZ2MS42SDUuMDV6TTUuMDUgMTIuNDhoOC4wNnYxLjZINS4wNXpNNS4wNSA4LjkyaDguMDZ2MS42SDUuMDV6TTEyLjE3IDMuOGg5LjZ2MmgtOS42eiIvPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTE1Ljk4IDBoMnY5LjZoLTJ6Ii8+PC9nPjwvZz48L3N2Zz4="
            alt=""
            class="w-4 h-4 md:h-6 md:w-6"
        >
        <span>Order paper</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import Tabs from "@/components/layout/Tabs";

export default {
  name: "Header",
  components: {Tabs}
}
</script>

<style scoped>
.link_item {
  @apply hover:bg-green-600 px-4 py-2
}

.link_item_hover {
  @apply cursor-pointer
}

</style>
