<template>
  <div>
    <!--    left circle decoration-->
    <div class="absolute top-20 left-0 transform translate-x-4 translate-y-4">
      <div
          class="absolute bg-gradient-to-br from-teal-50 to-teal-200 w-32 h-32 md:w-64 md:h-64 rounded-full opacity-50"></div>
      <div
          class="absolute ml-10 mt-10 bg-gradient-to-br from-purple-50 to-purple-200 w-32 h-32 md:w-64 md:h-64 rounded-full opacity-50"></div>
      <div
          class="absolute ml-24 mb-12  bg-gradient-to-br from-red-50 to-red-200 w-32 h-32 md:w-64 md:h-64 rounded-full opacity-50"></div>
    </div>
    <!--    right circle decoration-->
    <div
        class="absolute top-0 right-0 md:mr-48 transform -translate-x-96 translate-y-96 md:-translate-x-64 md:translate-y-64">
      <div
          class="absolute bg-gradient-to-br from-teal-100 to-teal-200 w-24 h-24 md:w-64 md:h-64 rounded-full opacity-50"></div>
      <div
          class="absolute ml-10 mt-10 bg-gradient-to-br from-purple-100 to-purple-200 w-24 h-24 md:w-64 md:h-64 rounded-full opacity-50"></div>
      <div
          class="absolute ml-24 mt-12  bg-gradient-to-br from-red-100 to-red-200 w-24 h-24 md:w-64 md:h-64 rounded-full opacity-50"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LftRghtBtns"
}
</script>

<style scoped>

</style>
