<template>
  <div>
    <Hero/>
    <CTA/>
    <HowItWorks/>
    <div class="container mx-auto">
<!--      <h2 class="text-teal-600 text-3xl text-center mb-4 font-bold">-->
<!--        We are happy with some of our numbers-->
<!--      </h2>-->
      <our-numbers/>
    </div>
    <InstantHelp/>
    <our-advantages/>
    <Services/>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import Navigation from "@/components/layout/Navigation";
import Hero from "@/components/layout/Hero";
import CTA from "@/components/layout/CTA";
import HowItWorks from "@/components/layout/HowItWorks";
import Advantages from "@/components/layout/Advantages";
import InstantHelp from "@/components/layout/InstantHelp";
import Services from "@/components/layout/Services";
import Footer from "@/components/layout/Footer";
import OurNumbers from "@/components/layout/OurNumbers";
import OurAdvantages from "@/components/layout/OurAdvantages";

export default {
  name: "Home",
  components: {
    OurAdvantages,
    OurNumbers,
    Services,
    Advantages, CTA, Hero, Navigation, Footer, InstantHelp, HowItWorks, Header
  },
  data() {
    return {
      order: {
        paperType: {
          "id": 2,
          "title": "Essay (any type)"
        },
      },
      user: {
        email: '',
      },
    }
  },
  updated() {
    // this.$store.commit('saveOrder', this.order);
    // this.$store.commit('saveUser', this.user);
  },
}
</script>

<style>
.nav {
  @apply bg-blue-600 text-white;
}

.my-btn {
  @apply transition duration-500 ease-linear transform hover:-translate-y-1 hover:scale-110 py-3 px-8 text-lg rounded-lg font-bold tracking-wide;
}

.my-yellow-btn {
  @apply my-btn bg-yellow-600 hover:bg-yellow-200 hover:text-yellow-600 text-yellow-200;
}

</style>
