<template>
  <form action="" class="w-96">
      <div class="flex justify-between items-center my-2 space-x-2">
        <label for="email" class="">Email or ID</label>
        <input type="email" id="email" class="p-2 bg-gray-200 border-gray-600" placeholder="username@mail.com">
      </div>
      <div class="flex justify-between items-center my-2 space-x-2">
        <label for="password" class="">Password</label>
        <input type="password" id="password" class="p-2 bg-gray-200 border-gray-600">
      </div>
  </form>
</template>

<script>
export default {
name: "Login"
}
</script>

<style scoped>

</style>
