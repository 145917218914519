<template>
  <carousel-3d :autoplay="true" :autoplay-timeout="3000" :display="6" :height="320" :width="450">
    <slide v-for="(slide, i) in slides" key="i * 27 + 39" :index="i">
      <div class="py-12 flex flex-col items-center px-6">
        <h2 class="text-8xl text-green-600 font-extrabold">{{ slide.number }}</h2>
        <p class="text-xl my-3 text-center font-bold">{{ slide.achievement }}</p>
      </div>
    </slide>
  </carousel-3d>
</template>

<script>
import {Carousel3d, Slide} from 'vue-carousel-3d';

export default {
  components: [Carousel3d, Slide],
  name: "OurNumbers",
  data() {
    return {
      slides: [
        {
          number: "15",
          achievement: "Qualified specialists"
        },
        {
          number: "9.4/10",
          achievement: "Satisfaction rate"
        },
        {
          number: "2,700+",
          achievement: "Custom papers completed"
        },
        {
          number: "5+",
          achievement: "Years helping students"
        },
      ],
    }
  }
}
</script>

<style scoped>
.carousel-3d-container .carousel-3d-slide {
  padding: 20px;
}

.carousel-3d-container .carousel-3d-slide .title {
  font-size: 22px;
}

</style>
