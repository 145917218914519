<template>
  <div class="OrderMessages relative">
    <div class="OrderMessages__buttons flex items-center">
      <span class="px-3 font-bold text-xl">Order Messages</span>
      <button
          id="newMessage"
          class="my-3 mx-3 bg-blue-600 text-blue-100 rounded-lg p-3 OrderMessages__button UIButton UIButton-default-filled UIButton-default-filled--size-m UIButton-default-filled--color-primary"
          type="button"
          @click="isModalVisible = true"
      >New Message
      </button>
    </div>
    <div v-for="message in messages" :key="message.id">
      <div class="bg-gray-100">
        <div class="bg-white mx-auto border border-gray-200">
          <ul class="shadow-box">
            <li class="relative border-b border-gray-200">
              <button class="w-full px-8 py-6 text-left bg-gray-100" type="button"
                      @click="updateSelected(message.id)">
                <div class="flex items-center justify-between">
                  <span v-if="message.fromWide !== 'customer'">New message from {{ message.fromWide }}</span>
                  <span v-else>Me to {{ message.recipient }}</span>
                  <span>{{ formatDate(message.created) }}</span>
                </div>
              </button>
              <div
                  v-if="selected && message.id === selectedMessageId"
                  class="relative overflow-hidden transition-all duration-700">
                <div class="p-6">
                  <p>
                    {{ message.body }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <NewMessageModal :showing="isModalVisible" @close="isModalVisible = false"></NewMessageModal>
  </div>
</template>

<script>
import NewMessageModal from "@/components/order/NewMessageModal";
import axios from "axios";
import {formatDate} from "@/components/order/common-functions";
import {BASE_URL} from "@/components/order/orderObjects.js";

export default {
  name: "Messages",
  data() {
    return {
      isModalVisible: false,
      messages: [],
      show: false,
      selected: false,
      selectedMessageId: '',
    }
  },
  components: {NewMessageModal},
  mounted() {
    this.loadMessages(this.$route.params.id);
  },
  methods: {
    loadMessages(orderId) {
      axios({
        url: `${BASE_URL}/messages/${orderId}/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            this.messages = response.data;
          })
          .catch(error => {
            console.log(error);
            console.dir(error);
            console.debug(error);
          })
    },
    showMessage() {
      this.show = true;
    },
    updateSelected(messageId) {
      this.selected = true
      this.selectedMessageId = messageId
    },
    formatDate,
  }
}
</script>
