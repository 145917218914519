<template>
  <div class="container mx-auto">
    <div class="order_item_group">
      <label for="id_discipline" class="order_item_label">Discipline</label>
      <select
          v-model="order.discipline"
          id="id_discipline"
          class="order_item"
      >
        <option v-for="type in disciplines" :value="type" :key="type.id + type.title">
          {{ type.title }}
        </option>
      </select>
    </div>
    <div class="order_item_group">
      <label for="instructions" class="order_item_label">Instructions</label>
      <textarea
          v-model="order.paperDetails"
          id="instructions"
          placeholder="Write anything you feel is important for the writer to consider. An outline, a grading scale, and other documents may be uploaded as additional materials."
          class="order_item h-36 lg:h-24"
      ></textarea>
    </div>
    <div class="order_item_group">
      <label for="software" class="order_item_label">Software</label>
      <input
          v-model="order.software"
          id="software"
          placeholder="Software to be used, if there is any."
          class="order_item h-12"
      />
    </div>
    <div class="order_item_group">
      <label for="id_files" class="order_item_label">Additional materials</label>
      <input
          type="file"
          multiple
          id="id_files"
          class="py-4"
      />
    </div>
    <select-deadline :order="order"/>
    <select-task-size :order="order"/>

  </div>
</template>

<script>

import {
  PROGRAMMING_DISCIPLINES,
} from "@/components/order/orderObjects";
import SelectTaskSize from "@/components/form/order/SelectTaskSize";
import SelectDeadline from "@/components/form/order/SelectDeadline";

export default {
  name: "ProgrammingForm",
  components: {SelectDeadline, SelectTaskSize},
  data() {
    return {
      order: {
        instructions: '',
        software: '',
        paperDetails: '',
        discipline: {
          "id": 1,
          title: "Web programming",
        },
      },
      additionalMaterials: [],
      disciplines: PROGRAMMING_DISCIPLINES,
    }
  },
}
</script>

<style scoped>

</style>
