import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/tailwind.css'
import VueTelInput from 'vue-tel-input'
import VueFileAgent from 'vue-file-agent';
import {VueSpinners} from '@saeris/vue-spinners'
import Carousel3d from 'vue-carousel-3d';
import VueMeta from 'vue-meta';


Vue.use(VueMeta, {
    keyName: 'head'
});
Vue.use(Carousel3d);
Vue.use(VueSpinners)
Vue.use(VueFileAgent);
Vue.use(VueTelInput)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
