<template>
  <div class="py-12">
    <div class="max-w-7xl mx-auto px-6">
      <div class="lg:text-center">
        <h2 class="text-base text-teal-600 font-bold tracking-wide uppercase">Custom Writing Services</h2>
        <p class="my-2 text-3xl lg:text-5xl leading-8 font-extrabold tracking-tight text-teal-900">
          Our professional custom writing services
        </p>
        <p class="my-4 max-w-2xl text-xl text-gray-700 lg:mx-auto">
          Our academic essay writing service produces original papers in over 70 disciplines, allowing you to take
          charge of your studies. Our professionals can assist you with the following services:
        </p>
      </div>

      <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
          <div class="relative">
            <dt>
              <div
                  class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-teal-200 text-teal-600 shadow">
                <!-- Heroicon name: outline/globe-alt -->
                <svg aria-hidden="true" class="h-6 w-6" fill="none" stroke="currentColor"
                     viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-bold text-teal-600">Academic writing and research</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-600">
              Our writers can handle any type of academic assignment. If you need to prepare for a discussion, a
              seminar, or a custom-written paper (dissertation, essay, term paper, or essay), then our service is ideal.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                  class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-teal-200 text-teal-600 shadow">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-bold text-teal-600">Technical writing</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-600">
              Do you have a technical paper that you need to finish quickly? Then our firm is ideal for you! Our experts
              are qualified to advise you on technical aspects of a wide range of subjects, including information
              technology, engineering, genetics, and so on.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                  class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-teal-200 text-teal-600 shadow">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-bold text-teal-600">Creative writing</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-600">
              We create works of art that captivate readers and spark public debate. Based on our extensive experience
              dealing with engagement and publicity factors, we will create a compelling and easy-to-understand story
              that meets your needs.
            </dd>
          </div>

          <div class="relative">
            <dt>
              <div
                  class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-teal-200 text-teal-600 shadow">
                <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"/>
                </svg>
              </div>
              <p class="ml-16 text-lg leading-6 font-bold text-teal-600">Editing and proofreading</p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-600">
              You may have a draft that requires polishing so that it sounds professional, natural, error-free, and
              convincing. Our team detects and corrects syntax, grammar, and spelling errors. We will also paraphrase
              and explain difficult passages for your audience.
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Services"
};
</script>

<style scoped>

</style>
