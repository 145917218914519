<template>
  <div class="bg-gradient-to-t from-teal-100 via-blue-200 to-teal-200 text-gray-800 py-2 md:py-8">
    <div class="container mx-auto mt-20">
      <div class="py-3 px-4">
        <div class="lg:flex lg:justify-between">
          <div class="text-xl lg:w-1/2">
            <div class="relative">
              <h2 class="text-3xl md:text-5xl mb-2 font-bold">Our guarantees</h2>
              <div
                  class="absolute top-0 left-0 w-1/3 h-2 rounded bg-gradient-to-r from-orange-200 to-orange-600 transform -translate-y-4"></div>
              <div
                  class="absolute bottom-0 right-0 w-1/3 h-2 rounded bg-gradient-to-r from-pink-200 to-pink-600 transform translate-y-4"></div>
            </div>
            <p class="leading-loose py-3">
              We have taken measures to secure your personal information, payments, and ensure the originality of your
              papers in order to provide our customers with academic writing services on which they can rely. All
              customers are safeguarded by
              <strong class="font-bold text-lg">Money Back Guarantee, Plagiarism-free Guarantee, Revision Policy,
                Privacy Policy, and Terms and Conditions.
              </strong>
              We encourage you to become acquainted and be updated on these policies.
            </p>
          </div>
          <img alt="satisfied customer guarantees" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
               src="../assets/svgs/our-gurantees.svg"/>
        </div>
        <div class="transform -translate-y-12 lg:-translate-y-0 lg:flex lg:flex-row-reverse lg:justify-between">
          <div class="lg:w-1/2 -mt-12 lg:mt-12">
            <h2 class="text-3xl md:text-4xl mb-2 font-bold">Money Back Guarantee</h2>
            <p class="leading-loose text-lg">
              A 100% money-back guarantee for all cases where you want to cancel
              your order for any reason. This secures your interests if you
              encounter any problem at any stage of your order. Refund requests are
              processed within 3 business days.
            </p>
            <!--            <router-link class="text-blue-600 hover:text-blue-700 hover:underline my-3 font-medium" to="/money-back-guarantee-policy">Read more about our money back guarantee</router-link>-->
          </div>
          <img alt="money back guarantee" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
               src="../assets/svgs/money-back.svg"/>
        </div>
        <div class="lg:flex lg:justify-between">
          <div class="lg:w-1/2 -mt-24 lg:mt-32">
            <h3 class="text-2xl md:text-3xl mb-2 font-bold">Plagiarism-Free Guarantee</h3>
            <p class="leading-loose text-lg">
              All papers are written from scratch to ensure unique deliverables to
              our customers. Before delivery, all papers pass through an in-house
              plagiarism-detection tool to guarantee originality.
            </p>
          </div>
          <img alt="plagiarism free guarantee" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
               src="../assets/svgs/plagiarism-free.svg"/>
        </div>
        <div class="lg:flex lg:justify-between lg:flex-row-reverse">
          <div class="lg:w-1/2 -mt-24 lg:mt-32">
            <h3 class="text-2xl md:text-3xl mb-2 font-bold">Revision Policy</h3>
            <p class="leading-loose text-lg">
              To ensure our high-quality academic assistance matches your needs, we
              complement our services with unlimited free revisions.
            </p>
          </div>
          <img alt="revision policy bestdissertations.org" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
               src="../assets/svgs/revision-policy.svg"/>
        </div>
      </div>
      <div class="lg:flex lg:justify-between">
        <div class="lg:w-1/2 lg:mt-32 px-3">
          <h3 class="text-2xl md:text-3xl mb-2 font-bold">Privacy Policy</h3>
          <p class="leading-loose text-lg">
            To safeguard your privacy, we collect minimal basic information only
            required to process your order (your email and phone number). This
            personal data is stored and processed in strict compliance with GDPR
            and other statutory provisions.
          </p>
        </div>
        <img alt="bestdissertations.org privacy policy" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
             src="../assets/svgs/privacy-policy.svg"/>
      </div>

      <div class="flex justify-between flex-row-reverse">
        <div class="lg:w-1/2 -mt-24 lg:mt-32 px-3">
          <h3 class="text-2xl md:text-3xl mb-2 font-bold">24/7 Support</h3>
          <p class="leading-loose text-lg mb-4">
            The support team is available around the clock via live chat and phone
            to answer your questions, correct mistakes, and address your academic
            fears.
          </p>
          <router-link class="px-4 py-2 bg-blue-600 hover:bg-blue-500 hover:text-white text-blue-100 uppercase rounded-lg font-bold"
                       to="/support">
            Support
          </router-link>
        </div>
        <img alt="24/7 support by bestdissertations.org" class="transform -translate-y-20 lg:-translate-y-0 lg:w-1/2"
             src="../assets/svgs/support.svg"/>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/layout/Navigation";
import Footer from "@/components/layout/Footer";

export default {
  name: "Guarantees",
  components: {Footer, Navigation},
  metaInfo: {
    title: "Our Guarantees at CustomWritingHive.com"
  },
  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>
