<template>
  <div id="app">
    <navigation/>
    <router-view/>
    <app-footer/>
  </div>
</template>
<style>
* {
  font-family: 'Montserrat', sans-serif;
}
</style>
<script>
import Navigation from "@/components/layout/Navigation";
import AppFooter from "@/components/layout/Footer";

export default {
  metaInfo: {
    title: "Custom essay writing service - 15+ experts to help you | CustomWritingHive.com",
    titleTemplate: "%s | Homework help service | A writing service you can trust",
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      {charset: 'utf-8'},
      {
        name: 'description',
        content: 'A custom writing service you can trust. Plagiarism-free guarantee. Contact us 24/7 to get professional writing help.'
      },
      {name: 'viewport', content: 'width=device-width, initial-scale=1'}
    ],
  },
  components: {
    AppFooter, Navigation
  },
  mounted() {
    document.title = "Custom essay writing service - 15+ experts to help you | CustomWritingHive.com";
  }
}

</script>
