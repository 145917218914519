<template>
  <footer class="relative bg-gradient-to-br from-gray-100 to-gray-300 text-gray-600 py-24">
    <!--    circles -->
    <div class="absolute top-0 left-0 transform translate-x-6 translate-y-6">
      <div class="absolute w-24 h-24 rounded-full bg-gradient-to-br from-teal-100 to-teal-200 opacity-50"></div>
      <div
          class="absolute w-24 h-24 ml-10 rounded-full bg-gradient-to-br from-purple-100 to-purple-200 opacity-50"></div>
      <div class="absolute w-24 h-24 -ml-6 mt-6 rounded-full bg-gradient-to-br from-red-100 to-red-200 opacity-50"></div>
    </div>
    <div class="relative container mx-auto px-6 lg:px-0">
      <!--      top footer -->
      <div
          class="text-left lg:text-center flex flex-col md:flex-row justify-center md:justify-around space-y-4 lg:space-y-0 lg:space-x-10">
        <div class="space-y-3">
          <p class="mb-3 font-bold text-gray-400 uppercase tracking-wide">Company</p>
          <router-link class="block hover:text-gray-800 hover:underline" to="/about">About</router-link>
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/support">Contact us</router-link>-->
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/blog">Blog</router-link>-->
        </div>
        <div class="space-y-3">
          <p class="mb-3 font-bold text-gray-400 uppercase tracking-wide">Services</p>
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/about">FAQ</router-link>-->
          <router-link class="block hover:text-gray-800 hover:underline" to="/guarantees">Guarantees</router-link>
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/support">Testimonials</router-link>-->
        </div>
        <div class="space-y-3">
          <p class="mb-3 font-bold text-gray-400 uppercase tracking-wide">Contacts</p>
          <router-link class="block hover:text-gray-800 hover:underline" to="/support">Contact us</router-link>

          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/about">Terms & Conditions</router-link>-->
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/blog">Privacy Policy</router-link>-->
          <!--          <router-link class="block hover:text-gray-800 hover:underline" to="/support">Revision Policy</router-link>-->
        </div>
      </div>
      <!--      bottom footer -->
      <div
          class="pt-8 mt-4 flex flex-col-reverse md:flex-row justify-between border-t border-white text-gray-500 text-sm lg:px-10">
        <!--        copyright -->
        <div class="my-4 md:my-0">Copyright &copy; 2020 - 2021</div>
        <div class="space-x-4">
          <router-link to="#">Telegram</router-link>
          <router-link to="#">Facebook</router-link>
          <router-link to="#">Twitter</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
</style>
