<template>
  <div class="container mx-auto">
    <div v-if="error" class="px-10 py-16 my-4 bg-red-300 text-white rounded-lg">
      <h2 class="text-2xl font-bold">Problem during new password set.</h2>
      <p class="py-3">
        Please try
        <router-link class="text-blue-600 hover:text-blue-700" to="/forgot-password">reset password</router-link>
        again or contact service support for further help.
      </p>
    </div>
    <div v-if="success" class="px-10 py-16 my-4 bg-green-300 text-white rounded-lg">
      <h2 class="text-2xl font-bold">New password set successful.</h2>
      <p class="py-3">
        You can
        <router-link class="text-blue-600 hover:text-blue-700" to="/auth">log in</router-link>
        to manage your orders.
      </p>
    </div>
    <div v-if="!success && !error" class="px-10 py-16 my-4">
      <form action="" @submit="handlePasswordSave($event)">
        <div v-if="errors.length" class="py-4 rounded-lg px-2 bg-red-300 text-white">
          <p v-for="error in errors" :key="error">{{ error }}</p>
        </div>
        <label for="new_password">Your new password</label>
        <input
            id="new_password"
            v-model="user.new_password"
            class="order_item"
            placeholder="New password"
            type="password"/>
        <button
            class="bg-blue-600 text-white uppercase font-bold hover:bg-blue-400 text-base my-2 px-4 py-2 rounded-full mb-4 transition duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
            type="submit"
        >Save
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {BASE_URL} from "@/components/order/orderObjects.js";

export default {
  name: "PasswordResetConfirm",
  data() {
    return {
      error: false,
      errors: [],
      success: false,
      user: {
        new_password: '',
        uid: '',
        token: ''
      }
    }
  },
  methods: {
    checkForm() {
      this.errors = [];
      if (this.user.new_password.length < 8) {
        this.errors.push('A user password must be at least 8 characters.');
      }
      if (!this.errors.length) {
        return true;
      }
    },
    handlePasswordSave(event) {
      event.preventDefault();
      this.user.uid = this.$route.params.uid;
      this.user.token = this.$route.params.token;
      if (this.checkForm()) {
        axios({
          url: `${BASE_URL}/auth/users/reset_password_confirm/`,
          data: this.user,
          method: 'POST',
        })
            .then(response => {
              this.success = true;
              console.log('response', response.data);
            })
            .catch(error => {
              this.error = true;
              console.log('error', error);
            });
      }
    }
  }
}
</script>

<style scoped>

</style>
