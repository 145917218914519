<template>
  <nav
      class="fixed z-10 top-0 px-2 py-4 bg-gradient-to-bl from-blue-50 via-gray-100 to-gray-200 font-bold w-full shadow-lg">
    <div class="container mx-auto md:flex md:justify-between">
      <div class="flex md:flex-none justify-between">
        <a aria-label="https://customwritinghive.com"
           class="text-white no-underline text-blue-600 hover:text-blue-300 hover:no-underline flex items-center space-x-2"
           href="/">
          <svg class="w-8 h-8 fill-current" height="172" viewBox="0 0 129 129" width="172"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M53.2 3.1C21.2 9-1.3 39.8 3.1 71.8c3 21.4 16.3 39.6 35.3 48.2 8.4 3.8 14.3 5.2 24 5.5 17.4.6 33.2-5.8 45.6-18.4 8.6-8.8 13.2-16.6 16-27.7 5.7-22.1-2.2-47.3-19-61.4l-4.7-3.9-4.3 9.2c-2.4 5.1-6.1 13-8.3 17.7-2.1 4.7-5.3 11.3-7 14.8-3.4 7.1-3.1 10.7 1.2 12.8 4.6 2.3 6.2.6 14.1-15.3 4.1-8.4 7.8-15.5 8.2-15.8 1-1 5.4 8.2 7.2 15 2 7.7 1.4 20.3-1.2 27.2-7.6 19.9-24.7 32.3-45.1 32.8-28.3.6-51.2-22.7-49.6-50.6.5-9.6 2.5-16.1 7.3-23.5 4.9-7.4 9.5-12 16.4-16.1 5.2-3.2 18.1-7.3 19.3-6.1.3.3-2.2 6.3-5.6 13.4-3.3 7.1-6.5 13.9-7 15.2-2 5.2 2.2 10.1 7.6 8.8 2.2-.6 3.7-2.8 9.1-14.2 3.5-7.5 6.4-13.7 6.4-13.9 0-.2 2.2-5 5-10.6 4.2-8.8 4.7-10.5 3.4-11.2-2.1-1.2-18.8-1.6-24.2-.6z"/>
            <path
                d="M79.3 15.7c-2.5 5.4-7 15-10 21.3-8.3 17.4-8.6 18.4-6.2 21.4 2 2.6 6.1 3.4 8.6 1.8.7-.4 5.4-9.8 10.6-20.7 5.2-11 10.3-21.9 11.5-24.3l2-4.2L91 8.5C88.3 7.1 85.6 6 85 6c-.6 0-3.2 4.4-5.7 9.7zM42.5 58.2c-.8 2 6.1 33 7.5 33.8.6.4 5.1-1.4 10-4 4.8-2.6 11.7-6.2 15.4-8.1C79 78 82 76.1 82 75.7c0-2.3-3.9-1.3-12.6 3.3-6.1 3.4-9.9 4.8-10.3 4.2-.9-1.5-7.7-4.3-8.4-3.5-.3.3-.9-.8-1.2-2.3-.4-1.6-.9-3.7-1.1-4.6-.3-.9-.7-2.7-.9-4-1.9-10.3-3.7-14.1-5-10.6z"/>
          </svg>
          <span class="hidden lg:inline-block text-xl md:text-lg italic">CustomWritingHive</span>
        </a>
        <div class="md:hidden flex space-x-2 items-center">
          <router-link class="text-lg text-orange-600 hover:text-orange-700 font-bold" title="Order now" to="/order">
            Order now
          </router-link>
          <button class="rounded-full p-2 text-blue-600 bg-blue-100 hover:bg-blue-200" name="dashboard" type="button">
            <router-link name="dashboard" to="/dashboard">
              <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                   stroke-width="2" viewBox="0 0 24 24">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                <circle cx="12" cy="7" r="4"/>
              </svg>
            </router-link>
          </button>
          <button class="rounded-full p-2 text-blue-600 bg-blue-100 focus:outline-none hover:bg-blue-200" name="menu"
                  type="button"
                  @click="isOpen = !isOpen">
            <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
              <path v-if="!isOpen" d="M4 6h16M4 12h16M4 18h16" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"></path>
              <path v-if="isOpen" d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="2"></path>
            </svg>
          </button>
        </div>
      </div>
      <div :class="isOpen ? 'block mt-2' : 'hidden'"
           class="text-blue-600 text-xl md:text-base md:flex md:items-center space-x-3 px-4">
        <router-link class="nav-link ml-3 md:-ml-0" title="About us" to="/about">About us
        </router-link>
        <router-link class="nav-link" title="Guarantees" to="/guarantees">Guarantees</router-link>
        <router-link class="nav-link" title="Support" to="/support">Support</router-link>
        <router-link title="Dashboard" to="/dashboard">
          <button
              class=" text-blue-600 bg-blue-100 hover:bg-blue-200 rounded-full p-2 hidden md:block">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                 stroke-width="2" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
              <circle cx="12" cy="7" r="4"/>
            </svg>
          </button>
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: "Navigation",
  data() {
    return {
      isOpen: false,
    }
  }
}
</script>

<style scoped>
.nav-link {
  @apply block hover:text-blue-400 lg:px-4 py-2 hover:underline;
}
</style>
