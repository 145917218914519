<template>
  <div>
    <Header/>
    <!--    check order payment details -->
    <div v-if="!order.paid">
      <div class="flex w-full max-w-sm mx-auto overflow-hidden bg-red-100 rounded-lg shadow-lg mt-4">
        <div class="flex items-center justify-center w-12 bg-blue-500">
          <svg class="w-6 h-6 text-white fill-current" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z"/>
          </svg>
        </div>

        <div class="px-4 py-2 -mx-3">
          <div class="mx-3">
            <span class="font-semibold text-blue-500 dark:text-blue-400">Your order is unpaid.</span>
            <p class="text-sm text-gray-600 dark:text-gray-200">Please follow the checkout process outlined below.</p>
            <p class="text-sm text-gray-600 dark:text-gray-200">
              In the meantime, we will keep you updated via the order messages below and via email.
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-center my-3">
        <div id="paypal-button-container" style="width: 30%"></div>
      </div>
    </div>

    <loading-spinner :loading="loading"/>
    <div v-if="!loading" class="Page OrderDetailsPage">
      <div class="Page__body Page__width">
        <div class="OrderPageInfoHeader">
          <div class="Page__heading OrderPageInfoHeader__page-heading">
            <div class="OrderPageInfoHeader__page-title uppercase text-2xl">{{ order.title }}</div>
            <div class="OrderPageInfoHeader__order-id" title="Order ID">
              <span class="OrderPageInfoHeader__light-label">#{{ order.id }}
              </span>
            </div>
          </div>
          <div class="OrderPageInfoHeader__page-subheading">
            <div class="OrderPageInfoHeader__status">
              <div class="OrderPageInfoHeader__status-indication">
                <div class="progress-segments single">
                  <div class="relative px-5 py-2 font-medium text-white group">
                    <span
                        class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform translate-x-0 -skew-x-12 bg-blue-500 group-hover:bg-blue-700 group-hover:skew-x-12"></span>
                    <span
                        class="absolute inset-0 w-full h-full transition-all duration-300 ease-out transform skew-x-12 bg-blue-700 group-hover:bg-blue-500 group-hover:-skew-x-12"></span>
                    <span
                        class="absolute bottom-0 left-0 hidden w-10 h-20 transition-all duration-100 ease-out transform -translate-x-8 translate-y-10 bg-blue-600 -rotate-12"></span>
                    <span
                        class="absolute bottom-0 right-0 hidden w-10 h-20 transition-all duration-100 ease-out transform translate-x-10 translate-y-8 bg-blue-400 -rotate-12"></span>
                    <span class="relative">{{ order.status }}</span>
                  </div>
                </div>
              </div>
              <div class="OrderPageInfoHeader__status-description">
                Your order has been {{ order.status ? order.status.toLowerCase() : "created" }}.
              </div>
            </div>
            <div class="OrderPageInfoHeader__deadline"><span class="OrderPageInfoHeader__light-label">
              Created: </span><span>{{ formattedDate }}</span>
            </div>
          </div>
        </div>
        <div class="OrderDetailsPage__tabs my-3">
          <div class="cursor-pointer OrderDetailsPage__tab OrderDetailsPage__tab--active"
               @click="openTab($event,'info')">Info
          </div>
          <div class="cursor-pointer OrderDetailsPage__tab" @click="openTab($event,'messages')">
            Messages
          </div>
          <div class="cursor-pointer OrderDetailsPage__tab" @click="openTab($event,'files')">Files
          </div>
          <div class="OrderDetailsPage__order-actions"></div>
        </div>
        <div id="info" class="OrderDetails tab-content">
          <div class="OrderDetails__split OrderDetails__split--info">
            <div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Academic level</div>
                <div class="OrderDetails__info-row__value">{{ filterAcademicLevel(order.academicLevel) }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Type of paper</div>
                <div class="OrderDetails__info-row__value">{{ filterPaperType(order.paperType) }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Discipline</div>
                <div class="OrderDetails__info-row__value">{{ filterDiscipline(order.discipline) }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Title</div>
                <div class="OrderDetails__info-row__value capitalize">{{ order.title }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Paper details</div>
                <div class="OrderDetails__info-row__value">
                  {{ order.paperDetails }}
                </div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Paper format</div>
                <div class="OrderDetails__info-row__value">
                  {{ filterPaperFormat(order.paperFormat) }}
                </div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Created</div>
                <div class="OrderDetails__info-row__value">{{ formattedDate }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Deadline</div>
                <div class="OrderDetails__info-row__value">{{ orderDeadline }}</div>
              </div>
              <div v-if="order.sources >= 1" class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Number of sources</div>
                <div class="OrderDetails__info-row__value">{{ order.sources }}</div>
              </div>
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">Assigned writer (ID)</div>
                <div class="OrderDetails__info-row__value">U310749</div>
              </div>
              <!--              <div class="OrderDetails__info-row">-->
              <!--                <div class="OrderDetails__info-row__label">Requested writer (ID)</div>-->
              <!--                <div class="OrderDetails__info-row__value">U310749</div>-->
              <!--              </div>-->
              <div v-if="order.pages >= 1" class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">
                  <span>{{ order.pages }} page<template v-if="order.pages>1">s</template> × ${{ order.cpp }}</span>
                </div>
                <div class="OrderDetails__info-row__value">
                  <template>${{ (order.pages * order.cpp).toFixed(2) }}</template>
                </div>
              </div>
              <div v-if="order.slides >= 1" class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">
                  <span>{{ order.slides }} slide<template v-if="order.slides>1">s</template> × $5.00</span></div>
                <div class="OrderDetails__info-row__value">
                  <template>${{ (order.slides * 5).toFixed(2) }}</template>
                </div>
              </div>
              <div v-if="order.charts >= 1" class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label">
                  <span>{{ order.charts }} chart<template v-if="order.charts>1">s</template> × $5.00</span></div>
                <div class="OrderDetails__info-row__value"><span>${{ (order.charts * 5).toFixed(2) }}</span></div>
              </div>
              <!--              <div class="OrderDetails__info-row">-->
              <!--                <div class="OrderDetails__info-row__label">Writer preferences</div>-->
              <!--                <div class="OrderDetails__info-row__value"><span>$2.96</span></div>-->
              <!--              </div>-->
              <div class="OrderDetails__info-row">
                <div class="OrderDetails__info-row__label bold">Grand total price</div>
                <div class="OrderDetails__info-row__value">
                  <span class="color price-value bold">${{ order.price }}</span></div>
              </div>
            </div>
          </div>
          <div class="OrderDetails__split OrderDetails__split--misc">
            <div v-if="order.paid">
              <div class="OrderDetails__reorder-pane">
                <a class="UIButton UIButton-default-filled UIButton-default-filled--size-m UIButton-default-filled--color-primary bg-blue-600 text-blue-100 p-2 rounded-lg"
                   href="#" type="button">Re-order</a></div>
            </div>
          </div>
        </div>
        <div id="messages" class="OrderDetails tab-content" style="display: none;">
          <Messages/>
        </div>
        <div id="files" class="OrderDetails tab-content" style="display: none;">
          <Files/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/layout/Header";
import axios from "axios";
import Messages from "@/components/order/Messages";
import Files from "@/components/order/Files";
import {
  ACADEMIC_LEVELS,
  BASE_URL,
  DEADLINES,
  DISCIPLINES,
  PAPER_FORMATS,
  PAPER_TYPES
} from "@/components/order/orderObjects";
import {addDurationToDate, durationForDeadlineId, formatDate} from "@/components/order/common-functions";
import router from '../../router'
import LoadingSpinner from "@/components/layout/LoadingSpinner";

export default {
  name: "OrderDetails",
  components: {LoadingSpinner, Files, Messages, Header},
  data() {
    return {
      order: {},
      ACADEMIC_LEVELS, DEADLINES, DISCIPLINES, PAPER_FORMATS, PAPER_TYPES,
      loaded: false,
      error: null,
      loading: true,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('fetchOrder', to.params.id)
          .then(() => next())
          .catch((e) => next(false));
    })
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.post = null
  //   getOrder(to.params.id, (err, order) => {
  //     this.setData(err, order)
  //     next()
  //   })
  // },
  async mounted() {
    document.title = 'My order'
    // paypal checkout integration
    const paypalScript = document.createElement('script');
    const paypalClientId = "ASwRzqoNP4aR78u3i3Vq5Vp1SRKo8pQyo1rKarRf76jyZEM-xqzcOmBGYHKYphDG0BSKX4F9yoEuKhhQ";
    paypalScript.src = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}`;
    paypalScript.async = true;
    paypalScript.addEventListener("load", this.setLoaded);

    // wait for order details then append script to head
    await this.fetchOrder(this.$route.params.id)
        .then(() => document.head.append(paypalScript))
        .catch(e => console.log(e));
  },
  computed: {
    formattedDate: function () {
      return formatDate(this.order.created);
    },
    orderDeadline: function () {
      const duration = durationForDeadlineId(this.order.deadline);
      const createdOn = this.order.created;
      return addDurationToDate(duration, createdOn);
    }
  },
  created() {
    // this.createPaypalButtons();
  },
  methods: {
    setData(err, order) {
      if (err) {
        this.error = err.toString();
      } else {
        this.order = order;
      }
    },
    openTab(event, tab) {
      const tabs = document.getElementsByClassName('tab-content');
      for (let i = 0; i < tabs.length; i++) {
        tabs[i].style.display = 'none';
      }
      document.getElementById(tab).style.display = 'block';
      const tabLinks = document.getElementsByClassName('OrderDetailsPage__tab');
      for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].className = tabLinks[i].className.replace(" OrderDetailsPage__tab--active ", " OrderDetailsPage__tab--not-active ");
      }
      document.getElementById(tab).style.display = 'block';
      event.currentTarget.className += " OrderDetailsPage__tab--active ";
    },
    async fetchOrder(id) {
      axios({
        url: `${BASE_URL}/orders/${id}/`,
        method: 'GET',
        headers: {
          'Authorization': `Token ${localStorage.getItem('token')}`
        }
      })
          .then(response => {
            this.order = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            console.dir(error);
            console.debug(error);
          })
    },
    filterAcademicLevel(id) {
      return this.ACADEMIC_LEVELS.filter(function (item) {
        return item.id === id;
      })[0]['title'];
    },
    filterPaperType(id) {
      return this.PAPER_TYPES.filter(function (item) {
        return item.id === id;
      })[0]['title'];
    },
    filterPaperFormat(id) {
      return this.PAPER_FORMATS.filter(function (item) {
        return item.id === id;
      })[0]['title'];
    },
    filterDiscipline(id) {
      return this.DISCIPLINES.filter(function (discipline) {
        return discipline.id === id;
      })[0]['name'];
    },
    async setLoaded() {
      this.loaded = true;
      await this.mountPaypalButton();
    },
    async mountPaypalButton() {
      paypal
          .Buttons({
            style: {
              shape: "pill",
              color: "gold",
              label: "checkout",
              size: "responsive",
            },
            createOrder: async function (data, actions) {
              // set order amount
              const amount = JSON.parse(localStorage.getItem("order")).price;
              return actions.order.create({
                purchase_units: [{
                  amount: {
                    value: amount,
                  }
                }]
              });
            },
            onApprove: async function (data, actions) {
              // This function captures the funds from the transaction.
              return actions.order.capture().then(function (details) {
                // get order id
                const order_id = JSON.parse(localStorage.getItem("order")).id;
                // This function shows a transaction success message to your buyer.
                const data = {
                  order_id,
                  payment_id: details.id,
                  status: details.status,
                  payer_email: details.payer.email_address,
                  amount: details.purchase_units[0].amount.value,
                }
                console.log('data data ...', data);
                // todo post payment details to our db
                // postPayment(this.order.id, data);
                axios({
                  url: `${BASE_URL}/payment/${data.order_id}/`,
                  data: data,
                  method: 'POST',
                  headers: {
                    'Authorization': `Token ${localStorage.getItem('token')}`
                  }
                })
                    .then(() => {
                      // redirect users to payment successful page
                      router.push('/thank-you');
                    })
                    .catch(() => {
                      router.push('/support');
                    });
              })
            }
          })
          .render("#paypal-button-container");
    }
  }
};
</script>

<style scoped>
@import '../../../src/assets/css/order.css';

</style>
