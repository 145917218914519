<template>
  <div class="mt-20 bg-gray-50">
    <div class="container mx-auto py-24 px-8">
      <h2 class="text-3xl font-bold">Contact us</h2>
      <p class="leading-loose py-5">
        Please get in touch with us if we can help you with your paper or if you have any further questions about our
        services.
      </p>
      <div class="md:grid grid-cols-3 gap-8 space-y-8 md:space-y-0">
        <div>
          <div class="flex items-center space-x-4 text-3xl text-teal-600 font-bold">
            <span>
              <svg class="fill-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm4.5 17.311-1.76-3.397-1.032.505c-1.12.543-3.4-3.91-2.305-4.497l1.042-.513L10.698 6l-1.053.52c-3.601 1.877 2.117 12.991 5.8 11.308l1.055-.517z"/></svg>
            </span>
            <h3 class="">Phone</h3>
          </div>
          <p class="font-medium text-lg ">
            UK, US, CA <span class="text-teal-600 text-sm"><a class="hover:underline hover:text-teal-700"
                                                              href="tel:+1-209-387-8566">1-209-387-8566</a></span>
          </p>
          <p class="my-2 text-sm font-normal">
            Phone working hours: Monday 00:00 AM - Saturday 08:00 PM (GMT+3).
          </p>
        </div>
        <div>
          <div class="flex items-center space-x-4 text-3xl text-teal-600 font-bold">
            <span>
              <svg class="fill-current" clip-rule="evenodd" fill-rule="evenodd" height="24" width="24"
                   xmlns="http://www.w3.org/2000/svg"><path
                  d="M24 20h-3v4l-5.333-4H8v-4h2v2h6.333L19 20v-2h3V9.999h-2v-2h4V20zM8.333 14 3 18v-4H0V-.001L18 0v14H8.333zM2 12h3v2l2.667-2H16V2L2 1.999V12z"/></svg>
            </span>
            <h3>Chat</h3>
          </div>

          <button
              class="flex space-x-4 items-center block my-2 py-2 px-5 rounded-full text-lg bg-teal-600 hover:bg-teal-700 text-teal-100 hover:text-white">
            <svg class="fill-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M22 3v13H10.357L6 19.105V16H2V3h20zm2-2H0v16.981h4V23l7-5.019h13V1zm-5 6H5V6h14v1zm0 2H5v1h14V9zm-6 3H5v1h8v-1z"/>
            </svg>
            <span>Live chat</span>
          </button>
          <a class="flex max-w-max space-x-4 items-center block my-2 py-2 px-5 rounded-full text-lg bg-teal-600 hover:bg-teal-700 text-teal-100 hover:text-white"
             href="https://m.me/108454788092900"
             target="_blank">
            <svg class="fill-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2zm0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm-2 10H8v2h2v6h3v-6h1.82l.18-2h-2v-.833c0-.478.096-.667.558-.667H15V6h-2.404C10.798 6 10 6.792 10 8.308V10z"/>
            </svg>
            <span class="text-sm lg:text-base">Facebook Messenger</span>
          </a>
          <a class="flex max-w-max space-x-3 items-center block my-2 py-2 px-5 rounded-full text-lg bg-teal-600 hover:bg-teal-700 text-teal-100 hover:text-white"
             href="https://wa.me/+17633431925"
             target="_blank">
            <svg class="fill-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="m.057 24 1.687-6.163a11.867 11.867 0 0 1-1.587-5.946C.16 5.335 5.495 0 12.05 0a11.817 11.817 0 0 1 8.413 3.488 11.824 11.824 0 0 1 3.48 8.414c-.003 6.557-5.338 11.892-11.893 11.892a11.9 11.9 0 0 1-5.688-1.448L.057 24zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
            </svg>
            <span>WhatsApp</span>
          </a>
          <a class="flex space-x-3 items-center max-w-max my-2 py-2 px-5 rounded-full text-lg bg-teal-600 hover:bg-teal-700 text-teal-100 hover:text-white"
             href="https://t.me/mguys"
             target="_blank">
            <svg class="fill-current" height="24"
                 style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421" width="24"
                 xml:space="preserve"
                 xmlns="http://www.w3.org/2000/svg"><path d="M18.384 22.779a1.19 1.19 0 0 0 1.107.145 1.16 1.16 0 0 0 .724-.84C21.084 18 23.192 7.663 23.983 3.948a.78.78 0 0 0-.26-.758.8.8 0 0 0-.797-.14C18.733 4.602 5.82 9.447.542 11.4a.827.827 0 0 0-.542.799c.012.354.25.661.593.764 2.367.708 5.474 1.693 5.474 1.693s1.452 4.385 2.209 6.615c.095.28.314.5.603.576a.866.866 0 0 0 .811-.207l3.096-2.923s3.572 2.619 5.598 4.062Zm-11.01-8.677 1.679 5.538.373-3.507 10.185-9.186a.277.277 0 0 0 .033-.377.284.284 0 0 0-.376-.064L7.374 14.102Z"/></svg>
            <span>Telegram</span>
          </a>
          <p class="py-3 leading-loose text-sm">
            We will be happy to speak with you via live chat, Facebook Messenger, Telegram, or WhatsApp, from Monday to
            Saturday, 00:00 AM to 08:00 PM, (GMT+3).
          </p>
        </div>
        <div>
          <div class="flex items-center space-x-4 text-3xl text-teal-600 font-bold">
            <svg class="fill-current" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M22 5v14H2V5h20zm2-2H0v18h24V3zm-2 16-6.526-6.618-3.445 3.483-3.418-3.525L2 19l5.051-8L2 5l10.029 7.446L22 5l-4.998 6.01L22 19z"/>
            </svg>
            <h3>Email</h3>
          </div>
          <a class="my-2 text-teal-600 hover:text-teal-700 hover:underline" href="mailto:info@customwritinghive.com">info@customwritinghive.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/layout/Navigation";
import AJourney from "@/components/layout/AJourney";
import Footer from "@/components/layout/Footer";

export default {
  name: "Support",
  components: {Footer, AJourney, Navigation},
  metaInfo: {
    title: "Support at CustomWritingHive.com"
  },
  mounted() {
    window.scrollTo(0, 0)
  },
};
</script>

<style scoped>

</style>
