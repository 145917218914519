<template>
  <div class="min-h-screen md:flex space-x-4">
    <div class="flex-1 flex overflow-hidden">
      <form id="academic" enctype="multipart/form-data" method="post">
        <div class="order_item_group">
          <label class="order_item_label" for="c_discipline">Discipline</label>
          <select
              id="c_discipline"
              v-model="order.discipline"
              class="order_item"
          >
            <option v-for="discipline in disciplines" :key="discipline.id + discipline.isComplexAssignment"
                    :value="discipline">
              {{ discipline.name }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="c_instructions">Instructions</label>
          <textarea
              id="c_instructions"
              v-model="order.paperDetails"
              class="order_item h-36 lg:h-24"
              placeholder="Write anything you feel is important for the writer to consider. An outline, a grading scale, and other documents may be uploaded as additional materials."
          ></textarea>
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="c_software">Software</label>
          <input
              id="c_software"
              v-model="order.title"
              class="order_item h-12"
              placeholder="Software to be used, if there is any."
          />
        </div>
        <div class="order_item_group">
          <label class="order_item_label" for="c_files">Additional materials</label>
          <input
              id="c_files"
              class="py-4"
              multiple
              type="file"
          />
        </div>
        <div class="order_item_group mt-3">
          <label class="order_item_label" for="c_deadline">Deadline</label>
          <select
              id="c_deadline"
              v-model="order.deadline"
              class="order_item"
              name="orderDeadline"
              @change="handleDeadline"
          >
            <option v-for="type in DEADLINES" :key="type.id" :value="type">
              {{ type.name }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <div class="order_item_label"></div>
          <div class="mb-2 mt-0 md:ml-2 text-xs">
            We'll send you the order for review by <span class="font-bold">{{ deliverBy }}</span>
          </div>
        </div>
        <div class="order_item_group mt-3">
          <label class="order_item_label" for="c_task_size">Task size</label>
          <select
              id="c_task_size"
              v-model="order.taskSize"
              class="order_item"
              name="orderTaskSize"
              @change="handleTaskSize"
          >
            <option v-for="task_size in CALCULATION_TASK_SIZES" :key="task_size.id" :value="task_size">
              {{ task_size.name }}
            </option>
          </select>
        </div>
        <div class="order_item_group">
          <div class="order_item_label"></div>
          <div class="flex space-x-1 max-w-max pl-8">
            <div class="w-1/2 mx-auto">
              <h3 class="font-bold text-base"> Example: </h3>
              <span class="text-sm">{{ order.taskSize.example }}</span>
            </div>
            <div class="w-1/2 mx-auto">
              <h3 class="font-bold text-base">Deliverables</h3>
              <ul class="space-y-2">
                <li v-for="(deliverable, index) in order.taskSize.deliverables"
                    class="flex space-x-2 text-sm items-center">
                  <img :src="CALCULATIONS_DELIVERABLES_ICONS_DATA[index]" class="fill-current h-4 w-4">
                  <span>{{ deliverable }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--    info -->
    <order-info :order="order" title="Problem solving"/>
  </div>
</template>

<script>
import {
  CALCULATION_TASK_SIZES,
  CALCULATIONS_DELIVERABLES_ICONS_DATA,
  CALCULATIONS_DISCIPLINES,
  CALCULATIONS_EXTRA_SMALL_DELIVERABLES,
  DEADLINES,
} from "@/components/order/orderObjects";
import {addDurationToDate, deadlineFactor, formatDeadline, taskSizeFactor,} from "@/components/order/common-functions";
import OrderInfo from "@/components/form/order/OrderInfo";

export default {
  name: "Calculations",
  components: {OrderInfo},
  data() {
    return {
      order: {
        paperType: {
          "id": 41,
          "title": "Calculations"
        },
        title: '',
        paperDetails: '',
        discipline: {
          "id": 59,
          "name": "IT, Web",
          "isComplexAssignment": true
        },
        deadline: {
          "id": 3,
          "name": "24 hours"
        },
        price: 30.00,
        taskSize: {
          id: 1,
          name: "Extra small",
          example: "up to 5 short practice problems or theoretical questions",
          deliverables: CALCULATIONS_EXTRA_SMALL_DELIVERABLES,
        },
        academicLevel: {
          "id": 1,
          "title": "High school"
        },
        paperFormat: {
          "id": 3,
          "title": "MLA"
        },
        isCalculations: true,
      },
      additionalMaterials: [],
      disciplines: CALCULATIONS_DISCIPLINES,
      today: "",
      duration: {hours: 24},
      DEADLINES,
      openTab: 1,
      CALCULATION_TASK_SIZES,
      CALCULATIONS_DELIVERABLES_ICONS_DATA,
    }
  },
  methods: {
    updateOrderPrice() {
      const BASE_CPP = 30.00;
      const deadline_factor = deadlineFactor(this.order.deadline.id);
      const task_size_factor = taskSizeFactor(this.order.taskSize.id);
      const factor = deadline_factor * task_size_factor;
      this.order.price = Number.parseFloat((BASE_CPP * factor).toFixed(2));
    },
    handleDeadline() {
      this.today = new Date();
      this.duration = formatDeadline(this.order.deadline.name);
    },
    handleTaskSize() {
    },
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
      this.order.taskSize = CALCULATION_TASK_SIZES.find(x => x.id === tabNumber);
    },
  },
  updated() {
    this.updateOrderPrice();
    this.$store.commit('saveOrder', this.order);
  },
  computed: {
    deliverBy: function () {
      this.handleDeadline();
      return addDurationToDate(this.duration, this.today);
    },
  }
}</script>

<style scoped>

</style>
