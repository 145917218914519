<template>
  <div class="w-56 md:relative my-4 md:my-0">
    <div class="bg-blue-100 w-56 border border-blue-200 text-blue-600 text-lg rounded-lg shadow-lg md:fixed">
      <div class="px-2 py-4">
        <div class="space-y-1 font-bold">
          <p id="paperType" class="text-sm">{{ title }}</p>
          <p id="discipline" class="text-xs leading-normal">{{ order.discipline.name }}</p>
          <p id="softwareD" class="text-xs uppercase text-blue-500 leading-loose">{{ order.title }}</p>
        </div>
      </div>
      <div class="flex flex-col justify-center m-2">
        <p v-if="order.price > 0" class="text-sm font-bold uppercase text-center block">
          Grand total: ${{ order.price }}
        </p>
        <button
            class="block flex space-x-2 items-center justify-center bg-yellow-600 text-white uppercase font-bold hover:bg-yellow-500 hover:text-yellow-100 text-xs lg:text-base px-2 py-2 rounded transition duration-300"
            @click="handleSubmit"
        >
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 15v2m-6 4h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2zm10-10V7a4 4 0 0 0-8 0v4h8z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
          <span>Safe checkout</span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "OrderInfo",
  props: {
    order: {
      type: Object,
    },
    title: {
      type: String,
    }
  },
  methods: {
    handleSubmit() {
      console.log('oder details', this.order);
      this.$store.dispatch('createOrder')
          .then(() => this.$router.push('dashboard'))
    },
  }
}
</script>

<style scoped>

</style>
