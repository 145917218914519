<template>
  <div class="container mx-auto">
    <div class="bg-gray-400 h-4"></div>
    <div class="transform -translate-y-32 md:-translate-y-9 rounded py-8 bg-gray-100 shadow-lg">
      <h2 class="text-center text-3xl font-bold text-black my-3">What makes us essential to every student's academic
        journey</h2>
      <div class="grid grid-cols-2 md:grid-cols-3 gap-8 px-4">
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">70+ disciplines covered</h3>
          <p class="text-center">
            Academic writers in 70+ disciplines to help create your custom paper.
          </p>
        </div>
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">100% custom papers</h3>
          <p class="text-center">
            Each paper is written from scratch and checked for plagiarism.
          </p>
        </div>
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">Affordable flexible prices</h3>
          <p class="text-center">
            Our affordable and flexible pricing policing is transparent and our customers have access to discounts.
          </p>
        </div>
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">Fast turnaround</h3>
          <p class="text-center">
            Our team has a fast turnaround with 97% of the papers arriving on time.
          </p>
        </div>
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">Free revisions</h3>
          <p class="text-center">
            Our writers are ready to review the deliverable till it meets all expectations.
          </p>
        </div>
        <div>
          <h3 class="font-medium text-2xl text-black my-2 text-center">Extra features</h3>
          <p class="text-center">
            Customers can order charts, slides, and other useful services. You have access to progressive delivery and
            part-by-part payment.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AJourney"
};
</script>

<style scoped>

</style>
