<template>
  <section class="bg-white dark:bg-gray-800">
    <div class="container px-6 py-8 mx-auto">
      <h2 class="text-teal-600 text-3xl text-center mb-4 font-bold">
        Some advantages you enjoy for using our custom writing services.
      </h2>
      <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        <div>
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2"/>
          </svg>
          <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white">Pocket friendly pricing</h1>
          <p class="mt-2 text-gray-500 dark:text-gray-400">
            To help your budget, you can always choose longer deadlines to keep the cost of your custom paper low.
          </p>
        </div>
        <div>
          <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"/>
          </svg>
          <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white">Short turnaround</h1>

          <p class="mt-2 text-gray-500 dark:text-gray-400">
            If the deadline is approaching quickly, complete an outstanding essay in no more than four hours.
          </p>
        </div>

        <div>
          <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z"/>
            <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z"/>
          </svg>
          <h1 class="mt-4 text-xl font-semibold text-gray-800 dark:text-white">70+ disciplines covered</h1>

          <p class="mt-2 text-gray-500 dark:text-gray-400">Whatever subject deadline you have coming up, you can always
            count on our professional academic writers to handle it flawlessly.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OurAdvantages"
}
</script>

<style scoped>

</style>
